import React, {useCallback, useEffect, useRef, useState} from "react";
import styles from "./styles";
import Stepper from "./Stepper";
import MileStonesCard from "./MileStoneCard";
import getMileStones from "../../../../../api/business/getMileStone";
import {useAppDispatch} from "app/store/hooks";
import {IGButton} from "app/components";
import {leadDocVerificationActions} from "app/store/leadDocVerification";
import {toggleLeadDocVerificationModal} from "app/store/leadDocVerification/leadDocVerification.reducer";
import ActionHistoryModal from "../ActiveLoan/DocVerificationQueue/ActionHistoryModal";
import {Stack} from "@mui/material";

const DrawerList = ({selectedPayload}) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const [currentMileStone, setCurrentMileStone] = useState({
    data: null,
    index: 0,
  });
  const counter = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [mileStones, setMileStones] = useState({
    loading: false,
    error: false,
    payload: [],
  });

  const getMileStoneOfCr = useCallback(() => {
    (async () => {
      try {
        if (selectedPayload && selectedPayload.id) {
          setMileStones({
            loading: true,
            error: false,
            payload: [],
          });
          const response = await getMileStones({
            accessToken: localStorage.getItem("authToken"),
            crId: selectedPayload.id,
          });

          if (response.statusCode === 200) {
            setMileStones({
              error: false,
              loading: false,
              payload: response.payload,
            });
          } else {
            setMileStones({
              error: true,
              loading: false,
              payload: [],
            });
          }
        }
      } catch (error) {
        setMileStones({
          error: true,
          loading: false,
          payload: [],
        });
      }
    })();
  }, [selectedPayload]);

  const isSameCreditManager =
    String(selectedPayload.creditManagerId) === String(localStorage.getItem("id"));

  const isBackMovement = currentMileStone.index !== currentIndex;

  const mileStonesRequestTimer = 20000;
  const otherRequestTimer = 19000;
  const isThere = useRef(false);

  useEffect(() => {
    if (currentMileStone?.data?.milestone !== "CR_GOLD_LOAN_BT_UPLOAD_KYC") {
      const timer = setInterval(() => {
        getMileStoneOfCr();
      }, mileStonesRequestTimer);
      if (mileStones.payload !== undefined) {
        for (let i = 0; i < mileStones.payload.length; i++) {
          const current = mileStones.payload[i];

          isThere.current = i === 0 && !current.isComplete;

          if (!current.isComplete && !isBackMovement) {
            setCurrentMileStone({
              index: i === 0 ? i : i - 1,
              data: i === 0 ? current : mileStones.payload[i - 1],
            });
            setCurrentIndex(i === 0 ? i : i - 1);
            break;
          }

          if (i === mileStones.payload.length - 1 && current.isComplete && !isBackMovement) {
            setCurrentMileStone({
              index: i,
              data: mileStones.payload[i],
            });
            setCurrentIndex(i);
          }
        }
      }

      return () => {
        clearInterval(timer);
      };
    }
  }, [
    getMileStoneOfCr,
    isBackMovement,
    mileStones.length,
    mileStones.payload,
    setCurrentMileStone,
  ]);

  useEffect(() => {
    if (counter.current === 0) {
      counter.current = 1;
      getMileStoneOfCr();
    }
  }, [getMileStoneOfCr, selectedPayload.id]);

  return (
    <div className={classes.container}>
      <Stack flexDirection="row" gap={2} justifyContent="space-between" p={2} mr={6}>
        <div className={classes.title}>VIEW ACTIVE LOAN</div>
        <IGButton
          variant="outlined"
          onClick={() => {
            dispatch(
              leadDocVerificationActions.getLeadDocTimeline({leadId: selectedPayload.leadId}),
            );
            dispatch(toggleLeadDocVerificationModal({modal: "actionHistory", value: true}));
          }}
          sx={{margin: 2, marginBottom: 0}}
        >
          Lead Action History
        </IGButton>
      </Stack>
      <Stepper
        setCurrentMileStone={setCurrentMileStone}
        index={currentMileStone.index}
        mileStones={mileStones.payload}
        setCurrentIndex={setCurrentIndex}
        isBackMovement={isBackMovement}
        currentMileStone={currentMileStone}
        isThere={isThere.current}
      />

      <div className={classes.mileStonesContainer}>
        <MileStonesCard
          {...{
            currentMileStone,
            isBackMovement,
            isSameCreditManager,
            selectedPayload,
            setCurrentMileStone,
            setCurrentIndex,
            mileStones,
            currentIndex,
            otherRequestTimer,
            getMileStoneOfCr,
          }}
        />
      </div>
      <ActionHistoryModal leadId={selectedPayload.leadId} />
    </div>
  );
};

export default DrawerList;
