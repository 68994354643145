import {activeLoansSelectors} from "app/store/activeLoans";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import leadDocVerificationActions from "app/store/leadDocVerification/leadDocVerification.actions";
import {GetDocVerificationListPayload} from "app/infra/services/api/leadDoc/leadDoc.api";
import ActionHistoryModal from "./ActionHistoryModal";
import DocumentVerificationDrawer from "./Drawer";
import SkipVerificationModal from "./SkipVerificationModal";
import DocVerificationTable from "./Table";
import {selectLeadDocVerificationReload, selectOpenModals, toggleLeadDocVerificationModal} from "app/store/leadDocVerification/leadDocVerification.reducer";
import {authSelectors} from "app/store/auth";
import {RolesEnum} from "app/enums/roles";

const DocVerificationQueue = () => {
  const dispatch = useAppDispatch();
  const filtersData = useAppSelector(activeLoansSelectors.getFilters);
  const cityList = useAppSelector(activeLoansSelectors.getCitiesList);
  const reloadList = useAppSelector(selectLeadDocVerificationReload);
  const {
    docVerificationDrawer,
  } = useAppSelector(selectOpenModals);
  const user = useAppSelector(authSelectors.getUser);
  const {pageNo, pageSize} = useAppSelector(activeLoansSelectors.getPagination);
  const [selectedRow, setSelectedRow] = useState(-1);
  const queueFetchInterval = useRef<NodeJS.Timeout | null>(null);

  const isReadOnly = [
    RolesEnum.SCHEDULER,
    RolesEnum.INSIDE_SALES_LOAN,
    RolesEnum.INSIDE_SALES_TEAM_LEAD,
    RolesEnum.SCHEDULER_TEAM_LEAD,
    RolesEnum.SCHEDULING_ADMIN,
  ].some((role) => {
    return user?.userRoleList.includes(role);
  });

  const city = useMemo(() => {
    if (filtersData.selectedCityId !== 0) {
      return cityList.find(city => city.id === filtersData.selectedCityId)?.cityEnum || "";
    }
    return "";
  }, [filtersData.selectedCityId, cityList]);

  const getDocVerificationList = useCallback(() => {
    const payload: GetDocVerificationListPayload = {
      pageNo,
      pageSize,
    };

    if (city) {
      payload.city = [city];
    }

    if (filtersData.mobile) {
      payload.mobile = filtersData.mobile;
    }
    dispatch(
      leadDocVerificationActions.getLeadDocVerificationList({
        ...payload,
      }),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNo, pageSize, city, filtersData.mobile, reloadList]);

  useEffect(() => {
    if (queueFetchInterval.current) {
      clearInterval(queueFetchInterval.current);
      getDocVerificationList();
      queueFetchInterval.current = setInterval(() => {
        getDocVerificationList();
      }, 60000);
    } else {
      getDocVerificationList();
      queueFetchInterval.current = setInterval(() => {
        getDocVerificationList();
      }, 180000);
    }

    return () => {
      if (queueFetchInterval.current) {
        clearInterval(queueFetchInterval.current);
      }
    };
  }, [getDocVerificationList]);

  return (
    <>
      <DocVerificationTable
        setSelectedRow={setSelectedRow}
        selectedRow={selectedRow}
        setDrawer={(open) => dispatch(toggleLeadDocVerificationModal({modal: "docVerificationDrawer", value: open}))}
        isReadOnly={isReadOnly}
      />

      {docVerificationDrawer && (
        <DocumentVerificationDrawer
          leadId={selectedRow}
          onClose={() => dispatch(toggleLeadDocVerificationModal({modal: "docVerificationDrawer", value: false}))}
        />
      )}
      <ActionHistoryModal leadId={selectedRow} />
      <SkipVerificationModal leadId={selectedRow} />
    </>
  );
};

export default DocVerificationQueue;
