import React, {useEffect, useMemo} from "react";
import {Box, Typography, Grid} from "@mui/material";
import IGKeyValue from "app/components/IGLabelValue";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import BankStatementDetails from "./BankStatementDetails";
import {
  personalDetailsActions,
  personalDetailsSelectors,
} from "app/store/loanRenewalRebook/personalDetails";
import GuarantorDocView from "../GuarantorDetails/GuarantorDocView";
import {IGLoading} from "app/components";

const ProfessionIncomeDetails = ({crId}: { crId: number }) => {
  const dispatch = useAppDispatch();
  const employmentDetails = useAppSelector(state =>
    activeLoansSelectors.getBtScoreCardEmploymentDetailsByCrId(state, crId),
  );

  const incomeDocs = useAppSelector(personalDetailsSelectors.getKycDocuments);

  const {getBtScoreCardEmploymentDetails: isEmploymentDetailsLoading} = useAppSelector(
    activeLoansSelectors.getLoading,
  );
  const {getKycDocuments: isDocLoading} = useAppSelector(personalDetailsSelectors.getLoading);

  const bankStatementDoc = incomeDocs?.find(doc => doc?.documentType === "BANK_STATEMENT");
  const filteredIncomeDocs = incomeDocs?.filter(doc => doc?.documentType !== "BANK_STATEMENT");

  const isLoading = isEmploymentDetailsLoading || isDocLoading;
  const professionDocs = useMemo(
    () => [
      ...(employmentDetails?.crTakeoverEmploymentDocumentDetailResponseDtoList.map(
        item => item?.documentTypeEnum,
      ) || []),
      "BANK_STATEMENT",
    ],
    [employmentDetails],
  );
  const professionDetails = useMemo(() => {
    if (employmentDetails) {
      const {
        negativeProfession,
        itrFilingStatus,
        profession,
        employmentTypeDisplayValue,
        workExperienceInYears,
        companyName,
        businessName,
      } = employmentDetails;
      return [
        {
          label: "Negative profession customer?",
          value: negativeProfession ? "Yes" : "No",
          color: negativeProfession ? "red" : "green",
        },
        {
          label: "Employment Type",
          value: employmentTypeDisplayValue,
        },
        {
          label: "Customer's Profession",
          value: profession,
        },
        {
          label: "Company/Business Name",
          value: companyName || businessName || "N/A",
        },
        {
          label: "Work Experience",
          value: workExperienceInYears || "N/A",
        },
        {
          label: "ITR Filing Status",
          value: itrFilingStatus ? "Yes" : "No",
        },
      ];
    }
  }, [employmentDetails]);

  useEffect(() => {
    dispatch(
      activeLoansActions.getBtScoreCardEmploymentDetails({
        crId: crId,
      }),
    );

    dispatch(
      activeLoansActions.getBtScoreCardBankStatementDetails({
        crId: crId,
      }),
    );
    dispatch(
      activeLoansActions.getBtScoreCardBankingAnalysis({
        crId: crId,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      personalDetailsActions.getKycDocuments({
        crId,
        isRenewRebook: false,
        documentTypeList: professionDocs,
      }),
    );
  }, [professionDocs, crId, dispatch]);

  return (
    <>
      {isLoading ? (
        <IGLoading height="10vh" />
      ) : (
        <Box
          bgcolor="#FAFAFA"
          p="24px 50px 24px 24px"
          borderRight="1px solid #0000001F"
          borderLeft="1px solid #0000001F"
        >
          <Typography variant="h6" fontWeight={600}>
            Profession
          </Typography>
          <Box mt={2} p={2} borderRadius="4px" bgcolor="#FFFFFF" border="1px solid #0000001F">
            {professionDetails?.map(item => (
              <IGKeyValue
                label={item?.label}
                value={String(item?.value)}
                valueProps={{
                  color: item?.color,
                }}
              />
            ))}
            {filteredIncomeDocs?.length > 0 && (
              <Grid container spacing={1}>
                {filteredIncomeDocs.map(document => (
                  <>
                    {document?.documentType === "BANK_STATEMENT" ? (
                      <></>
                    ) : (
                      <Grid item xs={6}>
                        <Typography
                          fontSize="14px"
                          fontFamily="roboto"
                          fontWeight={500}
                          lineHeight="48px"
                          sx={{
                            textTransform: "capitalize",
                          }}
                        >
                          {document?.documentType.replaceAll("_", " ").toLowerCase()}
                        </Typography>
                        <GuarantorDocView guarantorDocument={document} />
                      </Grid>
                    )}
                  </>
                ))}
              </Grid>
            )}
          </Box>
          {bankStatementDoc && (
            <BankStatementDetails crId={crId} bankStatementDoc={bankStatementDoc} />
          )}
        </Box>
      )}
    </>
  );
};

export default ProfessionIncomeDetails;
