import {Box, Stack, Typography} from "@mui/material";
import {getValidTime} from "_metronic/utils/moment";
import {IGDialog, IGLoading, IGTable} from "app/components";
import {IGTableColsInterface} from "app/components/IGTable";
import {DocVerificationTimeLine} from "app/infra/services/api/leadDoc/leadDoc.api";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {selectLeadDocTimeline, selectLeadDocVerificationAction, selectOpenModals, toggleLeadDocVerificationModal} from "app/store/leadDocVerification/leadDocVerification.reducer";
import React from "react";

const ActionHistoryModal = ({
  leadId,
}: {
  leadId: number;
}) => {
  const dispatch = useAppDispatch();
  const actionHistory = useAppSelector(state => selectLeadDocTimeline(state, leadId));
  const {actionHistory: isActionHistoryModalOpen} = useAppSelector(selectOpenModals);
  const {loading: isActionHistoryLoading} = useAppSelector(state =>
    selectLeadDocVerificationAction(state, "getLeadDocTimeline"),
  );

  const tableCols: IGTableColsInterface<DocVerificationTimeLine> = [
    {
      headerName: "Time",
      field: "createdAt",
      flex: 0.8,
      sortable: false,
      headerClassName: "disbursed-loans-header",
      renderCell: ({value}) => (
        <Box>
          <Typography fontSize={14} fontWeight={500}>
            {getValidTime(value).format("DD MMM, YY") || "-"}
          </Typography>
          <Typography fontSize={12} color="text.secondary">
            {getValidTime(value).format("hh:mm A") || "-"}
          </Typography>
        </Box>
      ),
    },

    {
      headerName: "Status",
      field: "docStatus",
      flex: 0.6,
      sortable: false,
      headerClassName: "disbursed-loans-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      headerName: "Category & Description",
      field: "descriptionList",
      flex: 2,
      sortable: false,
      headerClassName: "disbursed-loans-header",
      renderCell: ({value}) => (
        <Stack gap={1}>
          {value.map((desc: any) => (
            <Stack>
              <Typography fontSize={12} fontWeight={500}>
                {desc.documentTypeDisplayValue}
              </Typography>
              {desc.rejectionDescriptionList.map((reason: string) => (
                <Typography fontSize={12}>- {reason}</Typography>
              ))}
            </Stack>
          ))}
        </Stack>
      ),
    },
    {
      headerName: "Remarks",
      field: "remark",
      flex: 1,
      sortable: false,
      headerClassName: "disbursed-loans-header",
      renderCell: ({value}) => (
        <Typography fontSize={12} fontWeight={500}>
          {value}
        </Typography>
      ),
    },
    {
      headerName: "Agent",
      field: "agentName",
      flex: 1,
      sortable: false,
      headerClassName: "disbursed-loans-header",
      renderCell: ({value}) => (
        <Typography fontSize={12} fontWeight={500}>
          {value}
        </Typography>
      ),
    },
  ];

  return (
    <IGDialog
      title="Lead Action History"
      open={isActionHistoryModalOpen}
      hideFooter
      maxWidth="lg"
      onClose={() =>
        dispatch(
          toggleLeadDocVerificationModal({
            modal: "actionHistory",
            value: false,
          }),
        )
      }
    >
      <Box mt={2} p={1}>
        {isActionHistoryLoading ? (
          <IGLoading height="10vh" />
        ) : (
          <IGTable<DocVerificationTimeLine>
            getRowId={row => row?.createdAt}
            tableCols={tableCols}
            tableData={actionHistory}
            hideFooterPagination
            getRowHeight={() => "auto"}
            sx={{
              "& .disbursed-loans-header": {
                fontWeight: "bold",
                color: "#fff",
                backgroundColor: "#000000",
              },
              "& .disbursed-loans-header .MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .disbursed-loans-header .MuiSvgIcon-root": {
                fill: "white",
              },
              "& .MuiDataGrid-cell": {
                alignItems: "start",
                padding: "4px",
              },
            }}
          />
        )}
      </Box>
    </IGDialog>
  );
};

export default ActionHistoryModal;
