import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {authReducer} from "./auth";
import snackbarReducer from "./snackbar";
import {schedulingReducer} from "./scheduling";
import {giftCardConfigReducer} from "./giftCardConfig";
import {whitelistReducer} from "./users";
import {userTierConfigReducer} from "./userTierConfig";
import {refferalConfigReducer} from "./refferalConfig";
import {serviceDeskReducer} from "./serviceDesk";
import {internalUserReducer} from "./internalUser";
import {layoutReducer} from "./layout";
import {activeLoansReducer} from "./activeLoans";
import {customerProfileReducer} from "./customerProfile";
import igDialogReducer from "./igDialog";
import {leadReducer} from "./lead";
import {cityReducer} from "./city";
import {loanReducer} from "./loan";
import {addressReducer} from "./address";
import {loanSummaryReducer} from "./loanSummary";
import {loanPaymentReducer} from "./loanPayment";
import {lenderLoanReducer} from "./lenderLoan";
import {goldReleaseReducer} from "./goldRelease";
import ornamentsReducer from "./ornaments/ornaments.reducer";
import lenderCustomerProfileReducer from "./lenderCustomerProfile/lenderCustomerProfile.reducer";
import {collateralMovementReducer} from "./collateralMovement";
import bankDetailsReducer from "./loanRenewalRebook/bankDetails/bankDetails.reducer";
import occupationDetailsReducer from "./loanRenewalRebook/occupationDetails/occupationDetails.reducer";
import {loanRenewalRebookReducer} from "./loanRenewalRebook";
import {cifValidationReducer} from "./loanRenewalRebook/cifValidation";
import {personalDetailsReducer} from "./loanRenewalRebook/personalDetails";
import {creditDetailsReducer} from "./loanRenewalRebook/creditDetails";
import {transactionsReducer} from "./transactions";
import {lenderClosureJobReducer} from "./lenderClosureJob";
import {dialerReducer} from "./dialer";
import {featureFlagsReducer} from "./featureFlags";
import {leadDocVerificationReducer} from "./leadDocVerification";


const authConfig = {
  key: "auth",
  storage,
  blacklist: ["loading", "error", "isGoogleAuth", "otpId"],
};

export const appReducer = combineReducers({
  auth: persistReducer(authConfig, authReducer),
  snackbar: snackbarReducer,
  giftCardConfig: giftCardConfigReducer,
  scheduling: schedulingReducer,
  whitelistUser: whitelistReducer,
  userTierConfig: userTierConfigReducer,
  refferalConfig: refferalConfigReducer,
  serviceDesk: serviceDeskReducer,
  internalUser: internalUserReducer,
  layout: layoutReducer,
  activeLoans: activeLoansReducer,
  igDialog: igDialogReducer,
  customerProfile: customerProfileReducer,
  lead: leadReducer,
  city: cityReducer,
  loan: loanReducer,
  address: addressReducer,
  loanSummary: loanSummaryReducer,
  loanPayment: loanPaymentReducer,
  lenderLoan: lenderLoanReducer,
  goldRelease: goldReleaseReducer,
  ornaments: ornamentsReducer,
  lenderCustomerProfile: lenderCustomerProfileReducer,
  collateralMovement: collateralMovementReducer,
  bankDetails: bankDetailsReducer,
  occupationDetails: occupationDetailsReducer,
  loanRenewRebook: loanRenewalRebookReducer,
  cifDetails: cifValidationReducer,
  personalDetails: personalDetailsReducer,
  creditDetails: creditDetailsReducer,
  transactions: transactionsReducer,
  lenderClosureJob: lenderClosureJobReducer,
  dialer: dialerReducer,
  featureFlags: featureFlagsReducer,
  leadDocVerification: leadDocVerificationReducer,
});

export const rootReducer: any = (state: any, action: any) => {
  // Clear all data in redux store to initial.
  if (action.type === "RESET_STORE") state = undefined;

  return appReducer(state, action);
};
