import {Chip} from "@mui/material";
import {IGButton, IGTable} from "app/components";
import activeLoansApi from "app/infra/services/api/activeLoans/api";
import {RenewRebookItemDetail} from "app/infra/services/api/activeLoans/types";
import {activeLoansSelectors} from "app/store/activeLoans";
import {useAppSelector, useSnackBar} from "app/store/hooks";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {InfoOutlined} from "@mui/icons-material";
import tippy from "tippy.js";

export default function RenewRebookListing({filtersData}: any) {
  const [loading, setLoading] = useState(false);
  const [renewRebookList, setRenewRebookList] = useState<RenewRebookItemDetail[]>([]);

  const {showError} = useSnackBar();
  const cityList = useAppSelector(activeLoansSelectors.getCitiesList);

  const fetchRenewRebookList = useCallback(async () => {
    setLoading(true);

    const params = {
      ...(filtersData?.selectedCrId && {crId: Number(filtersData.selectedCrId)}),
      ...(filtersData?.selectedCityId && {cityId: filtersData.selectedCityId}),
      ...(filtersData?.source && {source: filtersData.source}),
    };

    const {error, response} = await activeLoansApi.getRenewRebookList(params);

    if (!error) {
      setRenewRebookList(response?.renewRebookActiveJobDtoList);
    } else {
      showError(error?.message);
    }
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersData]);

  const handleViewRequest = (serviceRequestId: number, oldLoanId: number) => {
    window.location.href = `/gold-loan/service-desk/loan-services/${oldLoanId}/close-and-renew/${serviceRequestId}`;
  };

  useEffect(() => {
    fetchRenewRebookList();
  }, [fetchRenewRebookList]);

  const tableCols = [
    {
      field: "crId",
      headerName: "CR ID",
    },
    {
      field: "name",
      headerName: "CUSTOMER NAME",
    },
    {
      field: "loanType",
      headerName: "LOAN TYPE",
    },
    {
      field: "formattedLoanAmount",
      headerName: "LOAN AMOUNT",
      sortComparator: (a: string, b: string) => {
        const amountA = parseFloat(a.replace(/[^\d.-]/g, ""));
        const amountB = parseFloat(b.replace(/[^\d.-]/g, ""));
        return amountA - amountB;
      },
    },
    {
      field: "pendingSince",
      headerName: "PENDING SINCE",
      renderCell: ({row}: { row: any }) => {
        const {pendingSince, failureReason} = row;
        const isOver30Minutes = pendingSince.includes("hour") || pendingSince.includes("day") || (pendingSince.includes("min") && parseInt(pendingSince) > 30);

        return (
            <>
              <Chip
                icon={<AccessTimeIcon />}
                label={pendingSince}
                variant="outlined"
                size="small"
                sx={{
                  borderColor: isOver30Minutes ? "#D32E2F" : "",
                  color: isOver30Minutes ? "#D32E2F" : "",
                  background: isOver30Minutes ? "#FBE9EA" : "",
                  "& .MuiChip-icon": {
                    color: isOver30Minutes ? "#D32E2F" : "",
                  },
                }}
              />
            {failureReason &&
              <InfoOutlined
                fontSize="small"
                color="info"
                sx={{marginLeft: "8px"}}
                onMouseEnter={(event) => {
                  tippy(event.currentTarget, {
                    content: failureReason,
                    placement: "top",
                  });
                }}
                onMouseLeave={(event) => {
                  tippy(event.currentTarget).destroy();
                }}
              />}
          </>
        );
      },
      sortComparator: (a: any, b: any) => {
        const parseTime = (timeString: string) => {
          if (timeString === "just now") return 0;
          const [amount, unit] = timeString.split(" ");
          const value = parseInt(amount);
          if (unit.startsWith("min")) return value;
          if (unit.startsWith("hour")) return value * 60;
          if (unit.startsWith("day")) return value * 1440;
          return 0;
        };
        return parseTime(a) - parseTime(b);
      },
      sortable: true,
    },
    {
      field: "cityName",
      headerName: "CITY",
    },
    {
      field: "agentName",
      headerName: "ASSIGNED TO",
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      headerClassName: "collateral-movement-header",
      sortable: false,
      renderCell: ({row}: { row: any }) => {
        const {serviceRequestId, oldLoanId} = row;
        return (
          <IGButton
            variant="outlined"
            color="inherit"
            onClick={() => handleViewRequest(serviceRequestId, oldLoanId)}
          >
            View Request
          </IGButton>
        );
      },
    },
  ];

  const tableData = useMemo(() => {
    if (!renewRebookList?.length) return [];

    return renewRebookList.map((item) => {
      const createdAtDate = new Date(item.createdAt);
      const now = new Date();
      const diffInMs = now.getTime() - createdAtDate.getTime();
      const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

      let timeAgo: string;

      if (diffInMinutes < 1) {
        timeAgo = "just now";
      } else if (diffInMinutes < 60) {
        timeAgo = `${diffInMinutes} min ago`;
      } else if (diffInMinutes < 1440) {
        const hours = Math.floor(diffInMinutes / 60);
        timeAgo = `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else {
        const days = Math.floor(diffInMinutes / 1440);
        timeAgo = `${days} day${days > 1 ? "s" : ""} ago`;
      }

      const formattedLoanAmount = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(item.loanAmount);

      return {
        ...item,
        pendingSince: timeAgo,
        formattedLoanAmount: formattedLoanAmount,
        cityName: cityList?.find((city) => city.id === item.cityId)?.name || "",
      };
    });
  }, [cityList, renewRebookList]);

  return (
    <IGTable<RenewRebookItemDetail>
      tableCols={tableCols}
      tableData={tableData}
      loading={loading}
      getRowId={(row) => row.crId}
      hideFooterPagination={true}
      hideFooter
    />
  );
}
