import React, {useMemo} from "react";
import {Box} from "@mui/material";
import IGKeyValue from "app/components/IGLabelValue";
import {activeLoansSelectors} from "app/store/activeLoans";
import {useAppSelector} from "app/store/hooks";
import {isNil} from "lodash";

const BankingHistoryAnalysis = ({crId}: { crId: number }) => {
  const bankingAnalysis = useAppSelector(state =>
    activeLoansSelectors.getBtScoreCardBankingAnalysisByCrId(state, crId),
  );
  const bankingRecordAnalysis = useMemo(() => {
    if (!bankingAnalysis) return [];

    const getFlagValue = (flag: boolean | null | undefined) => {
      if (isNil(flag)) return "N/A";
      return flag ? "Yes" : "No";
    };

    const getFlagColor = (flag: boolean | null | undefined, override: string | undefined) => {
      if (override && !isNil(flag)) return override;
      if (isNil(flag)) return "";
      return flag ? "green" : "red";
    };

    const getDetails = (label: string, flag: boolean | null, colorOverride?: string) => ({
      label,
      value: getFlagValue(flag),
      color: getFlagColor(flag, colorOverride),
    });

    const {
      isIncomeAtRegularInterval,
      chequeBounce,
      isCurrentBalanceInNegative,
      isGamblingRecordPresent,
      achBounce,
    } = bankingAnalysis;

    return [
      getDetails("Income At Regular Interval", isIncomeAtRegularInterval),
      getDetails("ACH Bounce", achBounce, achBounce ? "red" : "green"),
      getDetails("Cheque Bounce", chequeBounce, chequeBounce ? "red" : "green"),
      getDetails(
        "Negative Balance",
        isCurrentBalanceInNegative,
        isCurrentBalanceInNegative ? "red" : "green",
      ),
      getDetails(
        "Gambling Record",
        isGamblingRecordPresent,
        isGamblingRecordPresent ? "red" : "green",
      ),
    ];
  }, [bankingAnalysis]);

  return (
    <Box mt={2}>
      {bankingRecordAnalysis?.map(item => (
        <IGKeyValue
          label={item.label}
          value={item.value}
          valueProps={{
            color: item.color,
          }}
        />
      ))}
    </Box>
  );
};

export default BankingHistoryAnalysis;
