import {createAction} from "@reduxjs/toolkit";
import {
  AddNewLeadPayload,
  BulkLeadUploadPayload,
  DeleteDocumentPayload,
  GetAnalyticsPayload,
  GetLeadListPayload,
  GetParentLeadId,
  ParentLeadIdPayload,
  ParentLeadMasterDocumentConfigIdPayload,
  PostLeadReAttributePayload,
} from "app/infra/services/api/lead/lead.types";
import {GenericResponse} from "app/typings/api/goldApi.types";

const leadActionTypes = {
  GetPartnerLeadList: "[Lead] Get Partner Lead List",
  GetPartnerLeadListSuccess: "[Lead] Get Partner Lead List Success",
  GetPartnerLeadListFailure: "[Lead] Get Partner Lead List Failure",
  DownloadCSV: "[Lead] Download CSV",
  DownloadCSVSuccess: "[Lead] Download CSV Success",
  DownloadCSVFailure: "[Lead] Download CSV Failure",
  AddNewLead: "[Lead] Add New Lead",
  AddNewLeadSuccess: "[Lead] Add New Lead Success",
  AddNewLeadFailure: "[Lead] Add New Lead Failure",
  BulkLeadUpload: "[Lead] Bulk Lead Upload",
  BulkLeadUploadSuccess: "[Lead] Bulk Lead Upload Success",
  BulkLeadUploadFailure: "[Lead] Bulk Lead Upload Failure",
  GetAnalytics: "[Lead] Get Analytics",
  GetAnalyticsSuccess: "[Lead] Get Analytics Success",
  GetAnalyticsFailure: "[Lead] Get Analytics Failure",
  GetLeadUnQualifiedReasons: "[Lead] Get Lead UnQualified",
  GetLeadUnQualifiedReasonsSuccess: "[Lead] Get Lead UnQualified Success",
  GetLeadUnQualifiedReasonsFailure: "[Lead] Get Lead UnQualified Failure",
  GetParentLeadId: "[Lead] Get Parent Lead Id",
  GetParentLeadIdCreditDetail: "[Lead] Get Parent Lead Id Credit Detail",
  GetParentLeadDocumentConfig: "[Lead] Get Parent Lead Document Config",
  GetParentLeadDocument: "[Lead] Get Parent Lead Document",
  DeleteParentLeadDocument: "[Lead] Delete Parent Lead Document",
  UpdateParentLeadDocument: "[Lead] Update Parent Lead Document",
  UpdateLeadOnLSQ: "[Lead] Update Lead On LSQ",
  PostLeadReAttribute: "[Lead] Post Lead ReAttribute",
};

const leadActions = {
  getPartnerLeadList: createAction<GetLeadListPayload>(leadActionTypes.GetPartnerLeadList),
  getPartnerLeadListSuccess: createAction<GenericResponse>(
    leadActionTypes.GetPartnerLeadListSuccess,
  ),
  getPartnerLeadListFailure: createAction<GenericResponse>(
    leadActionTypes.GetPartnerLeadListFailure,
  ),
  downloadCSV: createAction(leadActionTypes.DownloadCSV),
  downloadCSVSuccess: createAction<GenericResponse>(leadActionTypes.DownloadCSVSuccess),
  downloadCSVFailure: createAction<GenericResponse>(leadActionTypes.DownloadCSVFailure),
  addNewLead: createAction<AddNewLeadPayload>(leadActionTypes.AddNewLead),
  addNewLeadSuccess: createAction<GenericResponse>(leadActionTypes.AddNewLeadSuccess),
  addNewLeadFailure: createAction<GenericResponse>(leadActionTypes.AddNewLeadFailure),
  bulkLeadUpload: createAction<BulkLeadUploadPayload>(leadActionTypes.BulkLeadUpload),
  bulkLeadUploadSuccess: createAction<GenericResponse>(leadActionTypes.BulkLeadUploadSuccess),
  bulkLeadUploadFailure: createAction<GenericResponse>(leadActionTypes.BulkLeadUploadFailure),
  getAnalytics: createAction<GetAnalyticsPayload>(leadActionTypes.GetAnalytics),
  getAnalyticsSuccess: createAction<GenericResponse>(leadActionTypes.GetAnalyticsSuccess),
  getAnalyticsFailure: createAction<GenericResponse>(leadActionTypes.GetAnalyticsFailure),

  getLeadUnQualifiedReasons: createAction(leadActionTypes.GetLeadUnQualifiedReasons),
  getLeadUnQualifiedReasonsSuccess: createAction<GenericResponse>(
    leadActionTypes.GetLeadUnQualifiedReasonsSuccess,
  ),
  getLeadUnQualifiedReasonsFailure: createAction<GenericResponse>(
    leadActionTypes.GetLeadUnQualifiedReasonsFailure,
  ),
  getParentLeadId: createAction<GetParentLeadId>(leadActionTypes.GetParentLeadId),
  getParentLeadIdCreditDetail: createAction<ParentLeadIdPayload>(
    leadActionTypes.GetParentLeadIdCreditDetail,
  ),
  getParentLeadDocumentConfig: createAction<ParentLeadIdPayload>(
    leadActionTypes.GetParentLeadDocumentConfig,
  ),
  getParentLeadDocument: createAction<
    ParentLeadIdPayload & ParentLeadMasterDocumentConfigIdPayload
  >(leadActionTypes.GetParentLeadDocument),
  deleteParentLeadDocument: createAction<DeleteDocumentPayload>(
    leadActionTypes.DeleteParentLeadDocument,
  ),
  updateParentLeadDocument: createAction<
    ParentLeadIdPayload & ParentLeadMasterDocumentConfigIdPayload & { formData: FormData }
  >(leadActionTypes.UpdateParentLeadDocument),
  updateLeadOnLSQ: createAction<ParentLeadIdPayload>(leadActionTypes.UpdateLeadOnLSQ),
  postLeadReAttribute: createAction<PostLeadReAttributePayload>(
    leadActionTypes.PostLeadReAttribute,
  ),
};

export default leadActions;

export {leadActionTypes};
