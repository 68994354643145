import React, {ReactNode, useState} from "react";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  ChipProps,
  InputLabelProps,
  FormControlProps,
} from "@mui/material";
import {SelectInputProps} from "@mui/material/Select/SelectInput";
import {CloseRounded} from "@mui/icons-material";

export type IGValueType = ReadonlyArray<string> | string[] | number[];
export type IGValueDisplayType = "chip" | "string";
type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[] ? ElementType : never;

export interface IGSelectMultipleOptionInterface<T> {
  text: string;
  value: ArrElement<T>;
  disabled?: boolean;
  hidden?: boolean;
}

export interface IGSelectMultipleProps<T> extends SelectProps<T> {
  name: string;
  value: T;
  onChange: SelectInputProps<T>["onChange"];
  options: IGSelectMultipleOptionInterface<T>[];
  helperText?: ReactNode;
  touched?: boolean;
  multiple?: boolean;
  displayType?: IGValueDisplayType;
  clearable?: boolean;
  chipOptions?: ChipProps;
  inputLabelOptions?: InputLabelProps;
  formControlProps?: FormControlProps
}

const valueWrapperStyle = {
  display: "flex",
  flexWrap: "wrap",
  gap: 0.5,
};

function IGSelectMultiple<T extends IGValueType>({
  label,
  id,
  name,
  required,
  value,
  onChange,
  error,
  clearable = false,
  options = [],
  touched = false,
  disabled = false,
  helperText = "",
  variant = "outlined",
  size = "small",
  color = "primary",
  fullWidth = true,
  displayType = "chip",
  placeholder = "",
  chipOptions = {
    variant: "filled",
  },
  inputLabelOptions = {},
  formControlProps = {},
  ...props
}: IGSelectMultipleProps<T>) {
  const onCloseButtonClick = (e: any, value: any[]) => {
    e.preventDefault();
    e.stopPropagation();
    const event = new PointerEvent("click");
    if (onChange && e.target) {
      onChange(
        {
          ...event,
          target: {
            ...e.target,
            value: value as T,
            name: name,
          },
          isDefaultPrevented(): boolean {
            return false;
          },
          isPropagationStopped(): boolean {
            return false;
          },
          nativeEvent: undefined,
          persist(): void {},
        },
        <></>,
      );
    }
  };

  const [open, setOpen] = useState(false);

  return (
    <FormControl
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      required={required}
      onClick={e => {
        if (disabled) return;
        if ((e.target as any).role === "option") return;
        setOpen(!open);
      }}
      {...formControlProps}
    >
      <InputLabel id={`input-label-${id || name}`} {...inputLabelOptions}>
        {label}
      </InputLabel>
      <Select
        multiple
        open={open}
        required={required}
        id={id || name}
        name={name}
        label={label}
        value={value}
        labelId={`input-label-${id || name}`}
        variant={variant}
        onChange={onChange}
        color={color}
        error={error}
        disabled={disabled}
        endAdornment={
          clearable &&
          value.length > 0 && (
            <IconButton
              size={size}
              disabled={disabled}
              onClick={e => onCloseButtonClick(e, [])}
              sx={{marginRight: 1.5}}
            >
              <CloseRounded />
            </IconButton>
          )
        }
        renderValue={selected => (
          <>
            {displayType === "chip" && (
              <Box sx={valueWrapperStyle}>
                {selected.map((value, index) => (
                  <Chip
                    key={value}
                    size={size}
                    color={disabled ? "default" : color}
                    label={options.find(o => o.value === value)?.text || value}
                    onDelete={e => {
                      if (disabled) return;
                      e.stopPropagation();
                      const updatedValues = [...selected];
                      updatedValues.splice(index, 1);
                      onCloseButtonClick(e, updatedValues);
                    }}
                    {...chipOptions}
                  />
                ))}
              </Box>
            )}
            {displayType === "string" && value.join(",")}
          </>
        )}
        sx={{
          "& .MuiSelect-select .notranslate::after": placeholder
            ? {
                content: `"${placeholder}"`,
                opacity: 0.42,
              }
            : {},
          "& legend": {display: label ? "block" : "none"},
          "& fieldset": {top: label ? -5 : 0},
        }}
        {...props}
      >
        {options.length > 0 ? (
          options.map((option, index) => (
            <MenuItem
              key={`input-option-${id || name}-${index}`}
              value={option.value}
              disabled={disabled}
              hidden={option?.hidden || false}
            >
              {option.text}
            </MenuItem>
          ))
        ) : (
          <MenuItem key={`input-option-${id || name}-empty`} value={undefined} disabled>
            No Options
          </MenuItem>
        )}
        {touched && <FormHelperText>{helperText}</FormHelperText>}
      </Select>
    </FormControl>
  );
}

export default IGSelectMultiple;
