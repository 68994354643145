import {createAction} from "@reduxjs/toolkit";
import {ApproveKYCPayload, GetDocVerificationListPayload, GetLeadDocTimelinePayload, LeadIdPayload, RejectKYCPayload} from "app/infra/services/api/leadDoc/leadDoc.api";

export const leadDocVerificationActionTypes = {
  GetLeadDocVerificationList: "[Lead Doc Verification] Get Lead Doc Verification List",
  GetLeadDocTimeline: "[Lead Doc Verification] Get Lead Doc Timeline",
  ApproveKYC: "[Lead Doc Verification] Approve KYC",
  RejectKYC: "[Lead Doc Verification] Reject KYC",
  SkipVerification: "[Lead Doc Verification] Skip Verification",
  AssignJobToUser: "[Lead Doc Verification] Assign Job To User",
  UnassignJob: "[Lead Doc Verification] Unassign Job",
  GetDocumentDetailList: "[Lead Doc Verification] Get Document Detail List",
  GetDocumentRejectionDetails: "[Lead Doc Verification] Get Document Rejection Details",
  RejectDocumentVerification: "[Lead Doc Verification] Reject Document Verification",
};

const leadDocVerificationActions = {
  getLeadDocVerificationList: createAction<GetDocVerificationListPayload>(
    leadDocVerificationActionTypes.GetLeadDocVerificationList,
  ),
  getLeadDocTimeline: createAction<GetLeadDocTimelinePayload>(
    leadDocVerificationActionTypes.GetLeadDocTimeline,
  ),
  approveKyc: createAction<ApproveKYCPayload>(leadDocVerificationActionTypes.ApproveKYC),
  rejectKyc: createAction<RejectKYCPayload>(leadDocVerificationActionTypes.RejectKYC),
  skipVerification: createAction<ApproveKYCPayload>(
    leadDocVerificationActionTypes.SkipVerification,
  ),
  assignJobToUser: createAction<LeadIdPayload>(leadDocVerificationActionTypes.AssignJobToUser),
  getDocumentDetailList: createAction<LeadIdPayload>(
    leadDocVerificationActionTypes.GetDocumentDetailList,
  ),
  getDocumentRejectionDetails: createAction<LeadIdPayload>(
    leadDocVerificationActionTypes.GetDocumentRejectionDetails,
  ),
  rejectDocumentVerification: createAction<LeadIdPayload>(
    leadDocVerificationActionTypes.RejectDocumentVerification,
  ),
  unassignJob: createAction<LeadIdPayload>(leadDocVerificationActionTypes.UnassignJob),
};

export default leadDocVerificationActions;