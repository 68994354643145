import React, {useMemo} from "react";
import {Button, ButtonProps, Menu, MenuItem} from "@mui/material";
import {ArrowDropDown} from "@mui/icons-material";

export interface IGMenuProps {
  open?: boolean
  onClose?: ()=>void
  actionButtonProps: {
    id: string;
    label?: string;
    icon?: JSX.Element;
    variant?: "text" | "outlined" | "contained";
    elem?: (props: any) => JSX.Element;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any;
    actionBtnIdProp?: string
    anchorElProp?: null | HTMLElement
  } & ButtonProps;
  actionMenuProps: {
    id: string;
    items: {
      id: string;
      label: string;
      renderCondition?: boolean;
      onClick: Function;
      renderElem?: () => JSX.Element;
      menuItemProps?: any;
    }[];
    menuProps?: any;
  };
}

const IGMenu: React.FC<IGMenuProps> = ({
  actionMenuProps,
  actionButtonProps,
  open = false,
  onClose,
}) => {
  const {
    id: buttonId,
    label: buttonLabel,
    icon: buttonIcon,
    elem: buttonElem,
    variant: buttonVariant,
    onClick: onButtonClick,
    actionBtnIdProp,
    anchorElProp,
  } = actionButtonProps;
  const {id: menuId, items: menuItems, menuProps} = actionMenuProps;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [actionButtonId, setActionButtonId] = React.useState<null | string>(
    null,
  );
  const isMenuOpen = Boolean(anchorEl) || open;
  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (onButtonClick) onButtonClick(event);
    setAnchorEl(event.currentTarget);
    setActionButtonId(event.currentTarget.id);
  };
  const handleActionMenuClose = () => {
    setAnchorEl(null);
    if(onClose) {
      onClose();
    }
  };

  const filteredMenuItems = useMemo(() => {
    const menuItemsList: JSX.Element[] = [];

    menuItems.forEach((menuItem) => {
      const {
        id,
        label,
        renderCondition,
        onClick,
        renderElem,
        menuItemProps,
      } = menuItem;

      renderCondition &&
        menuItemsList.push(
          <MenuItem
            key={id}
            id={id}
            onClick={(event) => {
              if (onClick) {
                onClick(event);
                handleActionMenuClose();
              }
            }}
            {...menuItemProps}
          >
            {renderElem ? renderElem() : label}
          </MenuItem>,
        );
    });

    return menuItemsList;
  }, [menuItems]);

  return (
    <>
      {buttonElem ? (
        <>
          {buttonElem({
            id: buttonId,
            "aria-controls": isMenuOpen ? menuId : undefined,
            "aria-haspopup": "true",
            "aria-expanded": isMenuOpen ? "true" : undefined,
            onClick: handleActionsClick,
          })}
        </>
      ) : (
        <Button
          variant={buttonVariant || "contained"}
          id={buttonId}
          aria-controls={isMenuOpen ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? "true" : undefined}
          onClick={handleActionsClick}
          endIcon={buttonIcon || <ArrowDropDown />}
        >
          {buttonLabel}
        </Button>
      )}
      <Menu
        id={menuId}
        anchorEl={anchorEl || anchorElProp}
        open={isMenuOpen && (actionButtonId === buttonId || actionBtnIdProp === buttonId)}
        onClose={handleActionMenuClose}
        MenuListProps={{
          "aria-labelledby": buttonId,
        }}
        {...menuProps}
      >
        {filteredMenuItems}
      </Menu>
    </>
  );
};

export default IGMenu;
