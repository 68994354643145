import React, {useCallback, useState, useEffect, useRef} from "react";
import getGoldLoanDetail from "api/business/getGoldLoanDetail";
import {Box, CircularProgress} from "@mui/material";
import styles from "../../../styles";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import getNextJob from "api/business/getNextJob";
import updateStatus from "api/business/updateStatus";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import GoldLoanChecker from "../GoldLoanChecker";
import {IGButton, IGLoading} from "app/components";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {transactionsActions, transactionsSelectors} from "app/store/transactions";
import {isNil} from "lodash";
import LenderApprovalDetails from "app/pages/home/GoldLoan/ActiveLoan/LenderApprovalDetails";

const PersonalLoanTaken = ({
  selectedPayload,
  ltv,
  getMileStoneOfCr,
  isBackMovement,
  goldLoanApplicationNo,
  onModalHandler,
  requesting,
  isSameCreditManager,
}) => {
  const classes = styles();
  const {showSuccess, showError} = useSnackBar();
  const dispatch = useAppDispatch();
  const lenderApprovalDetails = useAppSelector(activeLoansSelectors.getLenderApprovalDetails);
  const {
    requestLenderApproval: isLenderApprovalLoading,
    getLoanCheckerStatus: isLenderDetailsLoading,
  } = useAppSelector(activeLoansSelectors.getLoading);

  const cmJobStatus = useAppSelector(activeLoansSelectors.getCmJobLatestStatus);
  const {lenderApprovalBreachList, pocDetails} = lenderApprovalDetails ?? {};
  const {pocName, pocPhone, pocEmail, branchName} = pocDetails ?? {};

  const [state, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      goldLoan: null,
    },
    errorMessage: "",
  });
  const [isGoldLoanCheckerDisable, setIsGoldLoanCheckerDisable] = useState(true);

  const [approveButtonLoading, setApproveButtonLoading] = useState(false);

  const counter = useRef(0);
  const [glState, setGLState] = useState(true);

  const getDetails = useCallback(async () => {
    try {
      const [goldLoanResponse] = await Promise.all([
        getGoldLoanDetail({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          loanType: "GOLD_LOAN",
        }),
      ]);

      if (goldLoanResponse.statusCode === 200) {
        setStates({
          loading: false,
          error: false,
          payload: {
            goldLoan: goldLoanResponse.payload,
          },
          errorMessage: "",
        });
      } else {
        setStates({
          loading: false,
          error: true,
          payload: {
            goldLoan: null,
            personalLoan: null,
          },
          errorMessage: goldLoanResponse?.error?.toString(),
        });
      }
    } catch (error) {
      setStates({
        loading: false,
        error: true,
        payload: {
          goldLoan: null,
          personalLoan: null,
        },
        errorMessage: "Something went wrong",
      });
    }
  }, [selectedPayload.id]);

  useEffect(() => {
    if (counter.current === 0) {
      counter.current = 1;
      getDetails();
    }
  }, [getDetails]);

  const onClickHandler = useCallback(async () => {
    try {
      const response = await getNextJob({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
        jobType: "CM_PROCESS_LOAN",
      });

      if (response.statusCode === 200) {
        const updateStatusResponse = await updateStatus({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          crJobId: response.payload.id,
          remark: "",
          status: "CM_PROCESS_GOLD_LOAN_DISBURSAL_MAKER_DONE",
        });

        if (updateStatusResponse.statusCode === 200) {
          getDetails();
          setGLState(false);
          getMileStoneOfCr();
          showSuccess("LM has been notified to provide loan documents!");
          setIsGoldLoanCheckerDisable(true);
          setApproveButtonLoading(false);
        } else {
          showError(updateStatusResponse.error.toString());
        }
      } else {
        showError(response.error.toString());
      }
    } catch (error) {
      showError("Something went wrong");
    }
  }, [getDetails, getMileStoneOfCr, selectedPayload.id, showError, showSuccess]);

  const {goldLoan} = state.payload;

  const {checkerId} = goldLoan ?? {};

  useEffect(() => {
    if (selectedPayload.id) {
      dispatch(
        activeLoansActions.getLatestStatus({
          crId: selectedPayload.id,
          crJobType: "CM_PROCESS_LOAN",
        }),
      );
    }
  }, [dispatch, selectedPayload.id]);

  useEffect(() => {
    if (!isNil(goldLoan?.checkerId)) {
      dispatch(
        activeLoansActions.getLoanCheckerStatus({
          crId: selectedPayload.id,
        }),
      );
    }
  }, [goldLoan?.checkerId]);

  if (state.loading) {
    return <CircularProgress />;
  }

  if (state.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{state.errorMessage}</div>
      </div>
    );
  }

  if (!isGoldLoanCheckerDisable) {
    return (
      <GoldLoanChecker
        crId={selectedPayload.id}
        goldLoanApplicationNo={goldLoanApplicationNo}
        onClickHandler={onClickHandler}
        setIsGoldLoanCheckerDisable={setIsGoldLoanCheckerDisable}
        isGoldLoanCheckerDisable={isGoldLoanCheckerDisable}
        approveButtonLoading={approveButtonLoading}
        setApproveButtonLoading={setApproveButtonLoading}
        getDetails={getDetails}
        isSameCreditManager={isSameCreditManager}
      />
    );
  }

  return (
    <div>
      <div>
        <div className={classes.textContainer}>
          <div className={classes.text}>TOTAL LOAN VALUE / LTV</div>

          <div className={classes.subText}>
            {`${numberWithCurrencyAndComma(goldLoan.totalLoanAmount)} / ${ltv}%`}
          </div>
        </div>

        <div className={classes.textContainer}>
          <div className={classes.text}>GOLD LOAN INFORMATION</div>

          <div className={classes.subText}>
            {"Application number: "}
            {goldLoan.applicationNumber}
          </div>

          <div className={classes.subText}>{"Type: Gold Loan"}</div>

          <div className={classes.subText}>{`Loan Branch: ${goldLoan.loanBranch}`}</div>

          <div className={classes.subText}>{`Gold Loan Amount: ${numberWithCurrencyAndComma(
            goldLoan.totalLoanAmount,
          )}`}</div>

          <div className={classes.subText}>{`Lender: ${goldLoan.loanVendorName}`}</div>

          <div className={classes.subText}>{`Processing Fee: ${numberWithCurrencyAndComma(
            goldLoan?.processingFeeIncludingGst,
          )}`}</div>
          <div
            className={classes.subText}
          >{`Loan disburse amount to customer: ${numberWithCurrencyAndComma(
            goldLoan?.loanDisbursedAmount,
          )}`}</div>

          {selectedPayload.milestone !== "COMPLETED" &&
            selectedPayload.milestone !== "CLOSED" &&
            selectedPayload.milestone !== "CR_GOLD_LOAN_FRESH_CLOSED" &&
            selectedPayload.milestone !== "CR_GOLD_LOAN_BT_CLOSED" && (
              <div className={classes.subText}>{`CM Scheme Name: ${goldLoan.schemeType}`}</div>
            )}

          {/* <div
            className={classes.subText}
          >{`Scheme Selected: ${goldLoan.goldLoanSchemeName ||
            goldLoan.nucleusSchemeCode}`}</div> */}
        </div>
        {goldLoan.loanAccountNumber && (
          <div
            className={classes.subText}
          >{`Loan Account Number: ${goldLoan.loanAccountNumber}`}</div>
        )}

        {glState && !isBackMovement && !goldLoan.checkerId && (
          <Box mt={1}>
            <IGButton fullWidth color="golden" onClick={() => setIsGoldLoanCheckerDisable(false)}>
              Verify Gold Loan Details
            </IGButton>
          </Box>
        )}

        {isNil(checkerId) && (
          <IGButton color="inherit" onClick={onModalHandler} disabled={requesting}>
            RETURN TO GOLD VALUATION
          </IGButton>
        )}

        {!isNil(checkerId) && (
          <>
            {isLenderDetailsLoading ? (
              <IGLoading height="10vh" />
            ) : (
              <>
                {lenderApprovalBreachList?.map((item, index) => (
                  <LenderApprovalDetails
                    approvalDetails={item}
                    index={index}
                    isRenewRebookFlow={false}
                  />
                ))}

                {cmJobStatus === "CM_PROCESS_LOAN_GL_APPLICATION_APPROVED" && (
                  <Box mt={1} display="flex" justifyContent="flex-start" gap={1}>
                    <IGButton color="inherit" onClick={onModalHandler} disabled={requesting}>
                      RETURN TO GOLD VALUATION
                    </IGButton>
                    {lenderApprovalBreachList &&
                      lenderApprovalBreachList?.some(
                        item => item.lenderApprovalStatus === "Rejected",
                      ) && (
                        <IGButton
                          loading={isLenderApprovalLoading}
                          onClick={() => {
                            dispatch(
                              activeLoansActions.requestLenderApproval({
                                crId: selectedPayload.id,
                              }),
                            );
                          }}
                        >
                          Request Lender Approval
                        </IGButton>
                      )}
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PersonalLoanTaken;
