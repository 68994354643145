import React, {useEffect} from "react";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {IGDialog, IGLoading} from "app/components";
import {Box, Typography} from "@mui/material";
import IGTable, {IGTableColsInterface} from "app/components/IGTable";
import {TransactionSummaryAnalysis} from "app/models/activeLoans/activeLoans.model";

interface TransactionAnalysisModalProps {
  crId: number;
  transactionType: string;
}
const TransactionAnalysisModal = ({crId, transactionType}: TransactionAnalysisModalProps) => {
  const dispatch = useAppDispatch();
  const {transactionAnalysisModal: isTransactionAnalysisModalOpen} = useAppSelector(
    activeLoansSelectors.getOpenModals,
  );
  const {getTransactionAnalysis: isTransactionAnalysisLoading} = useAppSelector(
    activeLoansSelectors.getLoading,
  );
  const transactionAnalysis = useAppSelector(state =>
    activeLoansSelectors.getTransactionAnalysisByCrId(state, crId),
  );

  const tableCols: IGTableColsInterface<TransactionSummaryAnalysis> = [
    {
      field: "sourceAccountName",
      headerName: "Source Account Name",
      flex: 4,
      sortable: false,
      headerClassName: "transaction-analysis-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      sortable: false,
      headerClassName: "transaction-analysis-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "medianAmount",
      headerName: "Median Amount",
      sortable: false,
      headerClassName: "transaction-analysis-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "numberOfTransaction",
      headerName: "No. of Transactions",
      sortable: false,
      headerClassName: "transaction-analysis-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "maxAmount",
      headerName: "Max Amount",
      sortable: false,
      headerClassName: "transaction-analysis-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "averageBalanceAtTransaction",
      headerName: "Avg Balance",
      sortable: false,
      headerClassName: "transaction-analysis-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "frequency",
      headerName: "Frequency",
      headerClassName: "transaction-analysis-header",
      sortable: false,
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
  ];

  useEffect(() => {
    dispatch(
      activeLoansActions.getTransactionAnalysis({
        crId: crId,
        transactionType: transactionType === "DEBIT_PROFILE" ? "DEBIT" : "CREDIT",
      }),
    );
  }, []);
  return (
    <>
      <IGDialog
        maxWidth="lg"
        hideFooter
        title={`${transactionType?.replaceAll("_", " ").toLowerCase()} Analysis`}
        open={isTransactionAnalysisModalOpen}
        titleProps={{
          sx: {
            textTransform: "capitalize",
          },
        }}
        onClose={() =>
          dispatch(
            toggleModals({
              type: "transactionAnalysisModal",
              value: false,
            }),
          )
        }
      >
        {isTransactionAnalysisLoading ? (
          <IGLoading height="10vh" />
        ) : (
          <Box>
            <IGTable
              getRowId={row => row?.sourceAccountName}
              tableCols={tableCols}
              tableData={transactionAnalysis}
              hideFooter
              hideFooterPagination
              sx={{
                "& .transaction-analysis-header": {
                  fontWeight: "bold",
                  color: "#fff",
                  backgroundColor: "#000000",
                },
                "& .transaction-analysis-header .MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                "& .transaction-analysis-header .MuiSvgIcon-root": {
                  fill: "white",
                },
              }}
            />
          </Box>
        )}
      </IGDialog>
    </>
  );
};

export default TransactionAnalysisModal;
