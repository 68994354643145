import React, {FC, useEffect, useMemo} from "react";
import {useAppSelector} from "app/store/hooks";
import {Checkbox, FormGroup, FormControlLabel, Grid, Box, Paper} from "@mui/material";
import {activeLoansSelectors} from "app/store/activeLoans";
import {useFormik} from "formik";
import * as yup from "yup";
import IGButton from "app/components/Button/IGButton";
import {applyFilters, resetFiltersState} from "app/store/activeLoans/activeLoans.reducer";
import {useAppDispatch} from "app/store/hooks";
import {IGTextField, IGDatePicker, IGSelect} from "app/components";
import {IGSelectOptionInterface} from "app/components/IGSelect";
import {getValidTime} from "_metronic/utils/moment";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {useSearchParams} from "react-router-dom";
import {sourceEnum} from "app/enums/gold_loan/activeLoans/filter";

export const getInitialDate = (date: number) => {
  const initialDate = new Date(date);
  const day = initialDate.getDate();
  const month = initialDate.getMonth();
  const year = initialDate.getFullYear();

  const updatedDay = String(day).length === 1 ? `0${day}` : day;
  const updatedMonth = String(month).length === 1 && month < 9 ? `0${month + 1}` : month + 1;

  return `${year}-${updatedMonth}-${updatedDay}`;
};

const Filter: FC = () => {
  const {t} = useIGTranslation();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = useAppSelector(activeLoansSelectors.getActiveTab);
  const filtersData = useAppSelector(activeLoansSelectors.getFilters);
  const cityList = useAppSelector(activeLoansSelectors.getCitiesList);
  const {pageNo, pageSize} = useAppSelector(activeLoansSelectors.getPagination);
  const isDocVerificationTabActive = activeTab === "DOC_VERIFICATION";
  const pageFromURL = Number(searchParams.get("activeLoansPageNo"));
  const pageSizeFromURL = Number(searchParams.get("activeLoansPageSize"));
  const {
    selectedCityId,
    selectedCrId,
    fetchActive,
    fromDate,
    toDate,
    cmStatus,
    sortCriteria,
    sortOrder,
    mobile,
    source,
  } = filtersData;

  const validationSchema = yup.object().shape({
    selectedCityId: yup.number().required("Please enter a number"),
    fromDate: yup.number().test({
      name: "same",
      exclusive: false,
      params: {},
      message: "Start date must be less than end date",
      test: function(value, context) {
        const endDate = getValidTime(context.parent.toDate).startOf("day");
        return getValidTime(value)
          .startOf("day")
          .isBefore(endDate);
      },
    }),
    toDate: yup.number().test({
      name: "same",
      exclusive: false,
      params: {},
      message: "End date must be greater than start date",
      test: function(value, context) {
        const startDate = getValidTime(context.parent.fromDate).startOf("day");
        return getValidTime(value)
          .startOf("day")
          .isAfter(startDate);
      },
    }),
    selectedCrId: yup
      .number()
      .typeError("Please enter only numbers")
      .test("is-integer", "Please enter an integer value", value => {
        if (!value) {
          return true;
        }
        const intRegex = /^-?[0-9]+$/;
        return intRegex.test(String(value));
      }),
    mobile: yup
      .string()
      .matches(/^[0-9]+$/, "Please enter only numbers")
      .length(10, "Mobile number must be 10 digits"),
    // cmStatus: filtersData.cmStatus,
    // fetchActive: filtersData.fetchActive,
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    dirty,
    isSubmitting,
    submitForm,
    resetForm,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedCityId: selectedCityId,
      fromDate: fromDate,
      toDate: toDate,
      selectedCrId: selectedCrId,
      cmStatus: cmStatus,
      fetchActive: Boolean(fetchActive),
      sortCriteria: sortCriteria,
      sortOrder: sortOrder,
      mobile: mobile,
      source: source,
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      dispatch(applyFilters({...values}));
      actions.setSubmitting(false);
      actions.resetForm({values});
    },
  });

  const handleReset = () => {
    dispatch(resetFiltersState());
    resetForm();
  };

  const cityOptions: IGSelectOptionInterface<number>[] = useMemo(() => {
    const options = [
      {
        text: t("ALL"),
        value: 0,
      },
    ];
    for (const city of cityList) {
      options.push({
        text: city.name || "",
        value: city.id,
      });
    }
    return options;
  }, [cityList, t]);

  useEffect(() => {
    for (const [field, value] of Object.entries(filtersData)) {
      if (value && value !== "null") {
        searchParams.set(field, String(value));
      } else {
        searchParams.delete(field);
      }
      setSearchParams(searchParams, {replace: true});
    }
  }, [filtersData]);

  useEffect(() => {
    if (pageFromURL !== pageNo) {
      searchParams.set("activeLoansPageNo", String(pageNo));
    }
    if (pageSizeFromURL !== pageSize) {
      searchParams.set("activeLoansPageSize", String(pageSize));
    }
    setSearchParams(searchParams, {replace: true});
  }, [pageNo, pageSize, pageFromURL, pageSizeFromURL]);

  return (
    <Paper>
      <Box p={2}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <IGSelect<number>
                fullWidth={false}
                sx={{width: 180}}
                name="selectedCityId"
                label="Cities"
                value={values.selectedCityId || 0}
                onChange={handleChange}
                options={cityOptions}
              />
            </Grid>
            {isDocVerificationTabActive && (
              <Grid item xs={2}>
                <IGTextField
                  fullWidth={false}
                  name="mobile"
                  label="Mobile"
                  value={values.mobile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onWheel={e => (e.target as HTMLInputElement).blur()}
                  error={touched.mobile && Boolean(errors.mobile)}
                  touched={touched.mobile}
                  helperText={touched.mobile && errors.mobile}
                />
              </Grid>
            )}
            {!["RENEW_REBOOK", "DOC_VERIFICATION"].includes(activeTab) && (
              <>
                <Grid item xs={2}>
                  <IGDatePicker
                    disableFuture={true}
                    disablePast={false}
                    name="fromDate"
                    label={t("FROM")}
                    value={values.fromDate}
                    onChange={fromDate => {
                      setFieldValue("fromDate", getValidTime(fromDate).valueOf());
                    }}
                    sx={{
                      width: "auto",
                    }}
                    inputFormat="DD MMM, yyyy, dddd"
                    error={touched.fromDate && Boolean(errors.fromDate)}
                    touched={Boolean(touched.fromDate)}
                    helperText={touched.fromDate && errors.fromDate}
                  />
                </Grid>

                <Grid item xs={2}>
                  <IGDatePicker
                    disablePast={false}
                    name="toDate"
                    label={t("TO")}
                    value={values.toDate}
                    onChange={toDate => {
                      setFieldValue("toDate", getValidTime(toDate).valueOf());
                    }}
                    sx={{
                      width: "auto",
                    }}
                    inputFormat="DD MMM, yyyy, dddd"
                    error={touched.toDate && Boolean(errors.toDate)}
                    touched={Boolean(touched.toDate)}
                    helperText={touched.toDate && errors.toDate}
                  />
                </Grid>
                </>
            )}
            {!["DOC_VERIFICATION"].includes(activeTab) && (
                <Grid item xs={2}>
                  <IGTextField
                    fullWidth={false}
                    name="selectedCrId"
                    label="CR ID"
                    type="number"
                    value={values.selectedCrId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onWheel={e => (e.target as HTMLInputElement).blur()}
                    clearable
                    error={touched.selectedCrId && Boolean(errors.selectedCrId)}
                    touched={touched.selectedCrId}
                    helperText={touched.selectedCrId && errors.selectedCrId}
                  />
                </Grid>
            )}

            {!["CREDIT_QUEUE", "DOC_VERIFICATION", "RENEW_REBOOK"].includes(activeTab) && (
              <Grid item xs={2}>
                <FormGroup sx={{display: "inline-flex"}}>
                  <FormControlLabel
                    label="In-progress loans"
                    sx={{marginBottom: 0}}
                    control={
                      <Checkbox
                        id="fetchActive"
                        name="fetchActive"
                        checked={values.fetchActive}
                        onChange={handleChange}
                        inputProps={{"aria-label": "controlled"}}
                      />
                    }
                  />
                </FormGroup>
              </Grid>
            )}

            {["RENEW_REBOOK"].includes(activeTab) && (
              <Grid item xs={3}>
                <FormGroup sx={{display: "inline-flex"}}>
                  <FormControlLabel
                    label="Initiated from Consumer App"
                    sx={{marginBottom: 0}}
                    control={
                      <Checkbox
                        id="source"
                        name="source"
                        checked={!!values.source}
                        onChange={(e) => {
                          setFieldValue("source", e.target.checked ? sourceEnum.CONSUMER_APP : null);
                        }}
                        inputProps={{"aria-label": "controlled"}}
                      />
                    }
                  />
                </FormGroup>
              </Grid>
            )}

            <Grid display="flex" item xs={2} gap={2}>
              <IGButton variant="text" color="inherit" onClick={handleReset} loading={isSubmitting}>
                RESET
              </IGButton>

              <IGButton
                disabled={!dirty || !isValid}
                color={!dirty ? "inherit" : "primary"}
                type="submit"
                onClick={async () => {
                  if (dirty) {
                    await submitForm();
                  }
                }}
                loading={isSubmitting}
              >
                SEARCH
              </IGButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper>
  );
};

export default Filter;
