import {Stack, Typography} from "@mui/material";
import {IGDialog, IGTextField} from "app/components";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {leadDocVerificationActions} from "app/store/leadDocVerification";
import {
  selectLeadDocVerificationAction,
  selectOpenModals,
  toggleLeadDocVerificationModal,
} from "app/store/leadDocVerification/leadDocVerification.reducer";
import React, {useEffect} from "react";

const SkipVerificationModal = ({leadId}: { leadId: number }) => {
  const dispatch = useAppDispatch();
  const {skipVerification: isSkipVerificationModalOpen} = useAppSelector(selectOpenModals);
  const {loading: skipVerificationLoading} = useAppSelector(state =>
    selectLeadDocVerificationAction(state, "skipVerification"),
  );
  const [remarks, setRemarks] = React.useState("");

  const onSubmit = () => {
    dispatch(leadDocVerificationActions.skipVerification({leadId, remark: remarks}));
  };

  useEffect(() => {
    return () => {
      setRemarks("");
    };
  }, []);

  return (
    <IGDialog
      title="Skip Verification"
      open={isSkipVerificationModalOpen}
      onClose={() =>
        dispatch(toggleLeadDocVerificationModal({modal: "skipVerification", value: false}))
      }
      onConfirm={onSubmit}
      onCancel={() =>
        dispatch(toggleLeadDocVerificationModal({modal: "skipVerification", value: false}))
      }
      confirmProps={{
        disabled: !remarks,
        loading: skipVerificationLoading,
      }}
    >
      <Stack gap={2}>
        <Typography>Please provide more details</Typography>
        <IGTextField
          name="remarks"
          label="Remarks"
          value={remarks}
          onChange={e => setRemarks(e.target.value)}
          multiline
          rows={4}
        />
      </Stack>
    </IGDialog>
  );
};

export default SkipVerificationModal;
