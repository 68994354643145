import {goldApiService} from "app/infra/plugins/axios";
import {ApiResources, GenericResponse} from "app/typings/api/goldApi.types";
import {ParentLeadDocument} from "../lead/lead.types";

export const leadDocApiEndpoints: ApiResources = {
  GET_DOC_VERIFICATION_LIST: {
    URL: "/lead/document/list",
    METHOD: "GET",
  },
  GET_DOC_TIMELINE: {
    URL: "/lead/document/timeline",
    METHOD: "GET",
  },
  APPROVE_KYC: {
    URL: "/lead/document/approve",
    METHOD: "POST",
  },
  ASSIGN_JOB_TO_USER: {
    URL: "/lead/document/cm/assign",
    METHOD: "PUT",
  },
  UNASSIGN_JOB: {
    URL: "/lead/document/cm/remove",
    METHOD: "PUT",
  },
  REJECT_KYC: {
    URL: "/lead/document/reject",
    METHOD: "POST",
  },
  SKIP_VERIFICATION: {
    URL: "/lead/document/skip",
    METHOD: "POST",
  },
  GET_LEAD_DOCUMENT_LIST: {
    URL: "/lead/document/detail",
    METHOD: "GET",
  },
  GET_DOCUMENT_REJECTION_DETAILS: {
    URL: "/lead/document/rejection/detail",
    METHOD: "GET",
  },
};

export interface LeadIdPayload {
  leadId: number;
}

export interface GetDocVerificationListPayload {
  city?: Array<string>;
  pageNo?: number;
  pageSize?: number;
  mobile?: string;
  sortOrder?: string;
}

export interface DocVerificationJob {
  leadId: number;
  customerName: string;
  mobile: string;
  loanType: string;
  loanAmount: number;
  city: string;
  agentName: string;
  pendingSince: number;
  creditManagerId: number;
}

export interface DocVerificationTimeLine {
  docStatus: string;
  agentName: string;
  createdAt: number;
  descriptionList: Array<{
    documentType: string;
    documentTypeDisplayValue: string;
    rejectionDescriptionList: Array<string>;
  }>;
  remark: string;
}

export interface DocRejectionDetail {
  documentType: string;
  parentLeadMasterDocumentConfigMappingId: number;
  rejectionReasonList: Array<{
    rejectionReasonMasterConfigId: number;
    description: string;
  }>;
}

export interface DocVerificationTimelineResponse extends GenericResponse {
  response: Array<DocVerificationTimeLine>;
}

export interface DocVerificationListResponse extends GenericResponse {
  response: {
    pageNo: number;
    pageSize: number;
    totalCount: number;
    result: Array<DocVerificationJob>;
  };
}

export interface DocumentDetailListResponse extends GenericResponse {
  response: Array<ParentLeadDocument>;
}

export interface ApproveKYCPayload extends LeadIdPayload {
  remark?: string;
}

export interface RejectKYCPayload extends ApproveKYCPayload {
  fwcLeadDocRejectRequestDtoList: Array<{
    documentType: string;
    fwcParentLeadMasterDocumentConfigMappingId: number;
    rejectionReasonMasterConfigIdList: Array<number>;
  }>;
}

export interface GetLeadDocTimelinePayload extends LeadIdPayload {
  isParentLead?: boolean;
}

const leadDocApi = {
  getDocVerificationList: (
    payload: GetDocVerificationListPayload,
  ): Promise<DocVerificationListResponse> => {
    return goldApiService({
      resource: leadDocApiEndpoints.GET_DOC_VERIFICATION_LIST,
      options: {
        queryParams: {...payload},
      },
    });
  },

  getDocTimeline: ({
    leadId,
    isParentLead = false,
  }: GetLeadDocTimelinePayload): Promise<GenericResponse> => {
    return goldApiService({
      resource: leadDocApiEndpoints.GET_DOC_TIMELINE,
      options: {
        queryParams: {leadId, isParentLead},
      },
    });
  },

  approveKYC: ({leadId, remark}: ApproveKYCPayload): Promise<GenericResponse> => {
    return goldApiService({
      resource: leadDocApiEndpoints.APPROVE_KYC,
      options: {
        data: {leadId, remark},
      },
    });
  },

  assignJobToUser: ({leadId}: LeadIdPayload): Promise<GenericResponse> => {
    return goldApiService({
      resource: leadDocApiEndpoints.ASSIGN_JOB_TO_USER,
      options: {
        queryParams: {leadId},
      },
    });
  },

  rejectKYC: ({
    leadId,
    fwcLeadDocRejectRequestDtoList,
    remark,
  }: RejectKYCPayload): Promise<GenericResponse> => {
    return goldApiService({
      resource: leadDocApiEndpoints.REJECT_KYC,
      options: {
        data: {
          leadId,
          fwcLeadDocRejectRequestDtoList,
          remark,
        },
      },
    });
  },

  skipVerification: ({leadId, remark}: ApproveKYCPayload): Promise<GenericResponse> => {
    return goldApiService({
      resource: leadDocApiEndpoints.SKIP_VERIFICATION,
      options: {
        data: {leadId, remark},
      },
    });
  },

  getLeadDocumentList: ({leadId}: LeadIdPayload): Promise<GenericResponse> => {
    return goldApiService({
      resource: leadDocApiEndpoints.GET_LEAD_DOCUMENT_LIST,
      options: {
        queryParams: {leadId},
      },
    });
  },

  getDocumentRejectionDetails: ({leadId}: LeadIdPayload): Promise<GenericResponse> => {
    return goldApiService({
      resource: leadDocApiEndpoints.GET_DOCUMENT_REJECTION_DETAILS,
      options: {
        queryParams: {leadId},
      },
    });
  },

  unassignJob: ({leadId}: LeadIdPayload): Promise<GenericResponse> => {
    return goldApiService({
      resource: leadDocApiEndpoints.UNASSIGN_JOB,
      options: {
        queryParams: {leadId},
      },
    });
  },
};

export default leadDocApi;
