import {MoreVertRounded} from "@mui/icons-material";
import {Typography, IconButton, Chip} from "@mui/material";
import {getCurrentTime, getValidTime} from "_metronic/utils/moment";
import {IGMenu, IGTable} from "app/components";
import {IGTableColsInterface} from "app/components/IGTable";
import {RolesEnum} from "app/enums/roles";
import {DocVerificationJob} from "app/infra/services/api/leadDoc/leadDoc.api";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {authSelectors} from "app/store/auth";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import {leadDocVerificationActions} from "app/store/leadDocVerification";
import {selectAllLeadDocVerification, selectLeadDocVerificationAction, toggleLeadDocVerificationModal} from "app/store/leadDocVerification/leadDocVerification.reducer";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import moment from "moment-timezone";
import React, {useCallback, useEffect, useState} from "react";

const getRowClassName = (checkerData: number) => {
  const createdTime = getValidTime(checkerData);
  const now = getCurrentTime();
  const elapsedTime = moment.duration(now.diff(createdTime));
  const days = elapsedTime.days();
  const hours = elapsedTime.hours();
  const minutes = elapsedTime.minutes();
  if (minutes > 30 || hours > 0 || days > 0) {
    return "time-limit-exceeded";
  }
  return "";
};

const Timer = ({checkerDate}: {
  checkerDate: number;
}) => {
  const [elapsedTime, setElapsedTime] = useState("Calculating...");
  const [isTimeLimitExceeded, setIsTimeLimitExceeded] = useState(false);

  useEffect(() => {
    const calculateElapsedTime = (date: number) => {
      const createdTime = getValidTime(date);

      const now = getCurrentTime();
      const elapsedTime = moment.duration(now.diff(createdTime));

      const days = elapsedTime.days();
      const hours = elapsedTime.hours();
      const minutes = elapsedTime.minutes();

      if (minutes > 30 || hours > 0 || days > 0) {
        setIsTimeLimitExceeded(true);
      }

      return `${days > 0 ? `${days}d` : ""} ${hours}h ${minutes}m`;
    };

    const updateElapsedTime = () => {
      setElapsedTime(calculateElapsedTime(checkerDate));
    };

    updateElapsedTime(); // Initial calculation
    const intervalId = setInterval(updateElapsedTime, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [checkerDate]);

  return <Chip
    label={elapsedTime}
    size="small"
    color={isTimeLimitExceeded ? "error" : "primary"}
  />;
};

const DocVerificationTable = ({
  selectedRow,
  setSelectedRow,
  setDrawer,
  isReadOnly,
}: {
  selectedRow: number;
  setSelectedRow: (row: number) => void;
  setDrawer: (value: boolean) => void;
  isReadOnly: boolean;
}) => {
  const {showWarning} = useSnackBar();
  const dispatch = useAppDispatch();
  const user = useAppSelector(authSelectors.getUser);
  const leadList = useAppSelector(selectAllLeadDocVerification);
  const {pageNo, pageSize} = useAppSelector(activeLoansSelectors.getPagination);
  const {loading: jobListLoading} = useAppSelector(state =>
    selectLeadDocVerificationAction(state, "getLeadDocVerificationList"),
  );
  const {loading: assigningJob} = useAppSelector(state =>
    selectLeadDocVerificationAction(state, "assignJobToUser"),
  );
  const {loading: unassigningJob} = useAppSelector(state =>
    selectLeadDocVerificationAction(state, "unassignJob"),
  );

  const assignCRToMe = useCallback((crId: number) => {
    dispatch(
      leadDocVerificationActions.assignJobToUser({
        leadId: crId,
      }),
    );
  }, [dispatch]);

  const unassignJob = useCallback((crId: number) => {
    dispatch(
      leadDocVerificationActions.unassignJob({
        leadId: crId,
      }),
    );
  }, [dispatch]);

  const handlePageChange = useCallback((pageNo: number) => {
    dispatch(
      activeLoansActions.setActiveLoansPagination({
        pageNo,
      }),
    );
  }, [dispatch]);

  const handlePageSizeChange = useCallback((pageSize: number) => {
    dispatch(
      activeLoansActions.setActiveLoansPagination({
        pageSize,
      }),
    );
    handlePageChange(1);
  }, [dispatch, handlePageChange]);

  const tableCols: IGTableColsInterface<DocVerificationJob> = [
    {
      field: "customerName",
      headerName: "CUSTOMER NAME",
      headerClassName: "active-loans-header",
    },
    {
      field: "mobile",
      headerName: "MOBILE NUMBER",
      headerClassName: "active-loans-header",
    },
    {
      field: "loanType",
      headerName: "LOAN TYPE",
      headerClassName: "active-loans-header",
    },
    {
      field: "loanAmount",
      headerName: "LOAN AMOUNT",
      headerClassName: "active-loans-header",
      sortable: false,
      renderCell: ({value}) => <Typography>{numberWithCurrencyAndComma(value)}</Typography>,
    },
    {
      field: "pendingSince",
      headerName: "PENDING SINCE",
      headerClassName: "active-loans-header",
      renderCell: ({value}) => <>{value ? <Timer checkerDate={value} /> : "-"}</>,
    },
    {
      field: "city",
      headerName: "CITY",
      sortable: false,
      headerClassName: "active-loans-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "agentName",
      headerName: "IS RM",
      headerClassName: "active-loans-header",
    },
    {
      field: "actions",
      headerName: "ACTION",
      sortable: false,
      headerClassName: "active-loans-header",
      renderCell: ({row}) => {
        const {leadId, creditManagerId} = row;
        return (
          <IGMenu
            actionButtonProps={{
              id: `${leadId}_activeLoans_actions`,
              label: "more",
              onClick: () => {
                setSelectedRow(leadId);
              },
              elem: props => (
                <IconButton {...props} color="primary">
                  <MoreVertRounded />
                </IconButton>
              ),
            }}
            actionMenuProps={{
              id: `${leadId}_activeLoans_menu`,
              items: [
                {
                  id: "assign-to-me",
                  label: "Assign To Me",
                  renderCondition: !creditManagerId && !isReadOnly,
                  onClick: () => assignCRToMe(leadId),
                },
                {
                  id: "remove-assign",
                  label: "Unassign Job",
                  renderCondition:
                    !!creditManagerId && !isReadOnly,
                  onClick: () => unassignJob(leadId),
                },
                {
                  id: "slip-verification",
                  label: "Skip Verification",
                  renderCondition: !isReadOnly,
                  onClick: () => {
                    dispatch(
                      toggleLeadDocVerificationModal({modal: "skipVerification", value: true}),
                    );
                  },
                },
                {
                  id: "action-history",
                  label: "Action History",
                  renderCondition: true,
                  onClick: () => {
                    dispatch(
                      leadDocVerificationActions.getLeadDocTimeline({
                        leadId,
                      }),
                    );
                    dispatch(
                      toggleLeadDocVerificationModal({
                        modal: "actionHistory",
                        value: true,
                      }),
                    );
                  },
                },
              ],
            }}
          />
        );
      },
    },
  ];

  const onRowClickHandler = (row: DocVerificationJob) => {
    setSelectedRow(row.leadId);
    if (row.creditManagerId === Number(user?.id)) {
      setDrawer(true);
    } else {
      showWarning("Please assign the job to yourself first");
    }
    // dispatch(setSelectedCR(row));
  };

  const showActions =
    user?.userRoleList?.some(role =>
      [
        RolesEnum.ADMIN,
        RolesEnum.CREDIT_MANAGER,
        RolesEnum.SENIOR_OPERATIONS,
        RolesEnum.FOUNDER,
        RolesEnum.BUSINESS,
        RolesEnum.SCHEDULER,
        RolesEnum.SCHEDULER_TEAM_LEAD,
      ].includes(role),
    ) || false;

  return (
    <IGTable<DocVerificationJob>
        tableCols={tableCols}
        tableData={leadList}
        getRowId={row => row.leadId}
        selectedRowId={selectedRow}
        loading={jobListLoading || assigningJob || unassigningJob}
        onRowClick={onRowClickHandler}
        pagination={true}
        onPageSizeChange={handlePageSizeChange}
        onPageChange={handlePageChange}
        page={pageNo}
        pageSize={pageSize}
        totalRows={undefined}
        disabledNext={leadList.length < pageSize}
        disabledPrev={pageNo <= 1}
        paginationType="single"
        columnVisibilityModel={{
          actions: showActions,
        }}
        getRowClassName={({row}) => getRowClassName(row.pendingSince)}
        sx={{
          "& .active-loans-header": {
            fontWeight: "bold",
            color: "#fff",
            backgroundColor: "#000000",
          },
          "& .active-loans-header .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .active-loans-header .MuiSvgIcon-root": {
            fill: "white",
          },
        }}
      />
  );
};

export default DocVerificationTable;