import React from "react";
import {Box, Typography, Grid, Divider, Stack} from "@mui/material";
import IGTable, {IGTableColsInterface} from "app/components/IGTable";
import {BankStatementRecords} from "app/models/activeLoans/activeLoans.model";
import {useAppSelector} from "app/store/hooks";
import {activeLoansSelectors} from "app/store/activeLoans";
import BankingHistoryAnalysis from "./BankingHistoryAnalysis";
import {IGLoading} from "app/components";
import GuarantorDocView from "../../GuarantorDetails/GuarantorDocView";
import SpendingAnalysis from "../SpendingAnalysis";
import {KycDocuments} from "app/models/loanRenewalRebook/personalDetails.model";

const BankStatementDetails = ({
  crId,
  bankStatementDoc,
}: {
  crId: number;
  bankStatementDoc: KycDocuments;
}) => {
  const bankStatementRecords = useAppSelector(state =>
    activeLoansSelectors.getBtScoreCardBankingStatementDetailsByCrId(state, crId),
  );

  const {getBtScoreCardBankStatementDetails: isBankStatementRecordsLoading} = useAppSelector(
    activeLoansSelectors.getLoading,
  );

  const {bankStatemenAnalysisPeriodInMonths, isBankStatementAnalysisAvailable} = useAppSelector(
    state => activeLoansSelectors.getBtScoreCardBankingAnalysisByCrId(state, crId) ?? {},
  );

  const tableCols: IGTableColsInterface<BankStatementRecords> = [
    {
      field: "month",
      headerName: "Month",
      sortable: false,
      headerClassName: "bank-statement-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "creditAmount",
      headerName: "Credit",
      sortable: false,
      headerClassName: "bank-statement-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "debitAmount",
      headerName: "Debit",
      sortable: false,
      headerClassName: "bank-statement-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "closingBalanceAmount",
      headerName: "Closing Balance",
      sortable: false,
      headerClassName: "bank-statement-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
  ];

  return (
    <>
      {isBankStatementRecordsLoading ? (
        <IGLoading height="10vh" />
      ) : (
        <>
          <Typography my={2} variant="h6" fontWeight={600}>
            Bank Statement
          </Typography>

          <Box mt={2} p={2} borderRadius="4px" bgcolor="#FFFFFF" border="1px solid #0000001F">
            <Stack my={1} direction="row" display="flex" justifyContent="space-between">
              <Typography>
                {bankStatemenAnalysisPeriodInMonths
                  ? `${bankStatemenAnalysisPeriodInMonths} Month Banking History`
                  : "Banking History"}
              </Typography>
              <Typography color={isBankStatementAnalysisAvailable ? "green" : "red"}>
                {isBankStatementAnalysisAvailable ? "Available" : "Unavailable"}
              </Typography>
            </Stack>
            <IGTable
              getRowId={row => row.month}
              tableCols={tableCols}
              hideFooterPagination
              tableData={bankStatementRecords}
              hideFooter
              sx={{
                "& .bank-statement-header": {
                  fontWeight: "bold",
                  color: "#fff",
                  backgroundColor: "#000000",
                },
                "& .bank-statement-header .MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                "& .bank-statement-header .MuiSvgIcon-root": {
                  fill: "white",
                },
              }}
            />
            <BankingHistoryAnalysis crId={crId} />
            <Divider
              sx={{
                padding: "0.75rem",
              }}
            />

            <SpendingAnalysis crId={crId} />

            <Grid container my={2} spacing={1} pl={1}>
              {bankStatementDoc && (
                <Grid item xs={12}>
                  <Typography
                    fontSize="14px"
                    fontFamily="roboto"
                    fontWeight={600}
                    lineHeight="48px"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {bankStatementDoc?.documentType.replaceAll("_", " ").toLowerCase()}
                  </Typography>
                  <GuarantorDocView guarantorDocument={bankStatementDoc} />
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default BankStatementDetails;
