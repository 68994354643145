import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DocRejectionDetail, DocVerificationJob, DocVerificationTimeLine} from "app/infra/services/api/leadDoc/leadDoc.api";
import {createActionsInitialState, setActionError, setActionLoading, setActionSuccess} from "../redux.utils";
import {RootState} from "../store";
import {ParentLeadDocument} from "app/infra/services/api/lead/lead.types";

const leadDocVerificationJobAdaptor = createEntityAdapter<DocVerificationJob>({
  selectId: job => job.leadId,
});

export interface DocumentDetail extends ParentLeadDocument {
  documentType: string;
  documentTypeDisplayValue: string;
}

interface InitialState {
  leadDocVerificationList: ReturnType<typeof leadDocVerificationJobAdaptor.getInitialState>;
  leadDocTimeline: Record<number, DocVerificationTimeLine[]>;
  actions: ReturnType<typeof createActionsInitialState>;
  documentDetailList: Record<number, DocumentDetail[]>;
  reload: boolean;
  openModals: {
    "actionHistory": boolean;
    "skipVerification": boolean;
    "approveKyc": boolean;
    "rejectKyc": boolean;
    "docVerificationDrawer": boolean;
  }
  documentRejectionDetails: Record<number, DocRejectionDetail[]>;
}

type LeadDocVerificationActions =
  | "getLeadDocVerificationList"
  | "getLeadDocTimeline"
  | "approveKyc"
  | "rejectKyc"
  | "skipVerification"
  | "assignJobToUser"
  | "getDocumentDetailList"
  | "getDocumentRejectionDetails"
  | "unassignJob";

const initialState: InitialState = {
  leadDocVerificationList: leadDocVerificationJobAdaptor.getInitialState(),
  leadDocTimeline: {},
  documentDetailList: {},
  documentRejectionDetails: {},
  reload: false,
  openModals: {
    "actionHistory": false,
    "skipVerification": false,
    "approveKyc": false,
    "rejectKyc": false,
    "docVerificationDrawer": false,
  },
  actions: createActionsInitialState([
    "getLeadDocVerificationList",
    "getLeadDocTimeline",
    "approveKyc",
    "rejectKyc",
    "skipVerification",
    "assignJobToUser",
    "getDocumentDetailList",
    "getDocumentRejectionDetails",
    "unassignJob",
  ]),
};

const leadDocVerificationSlice = createSlice({
  name: "leadDocVerification",
  initialState,
  reducers: {
    leadDocVerificationListReceived: (state, action: PayloadAction<Array<DocVerificationJob>>) => {
      leadDocVerificationJobAdaptor.setAll(state.leadDocVerificationList, action.payload);
    },
    leadDocVerificationTimelineReceived: (
      state,
      action: PayloadAction<{leadId: number; timeline: DocVerificationTimeLine[]}>,
    ) => {
      state.leadDocTimeline[action.payload.leadId] = action.payload.timeline.sort(
        (a, b) => b.createdAt - a.createdAt,
      );
    },
    toggleModal: (state, action: PayloadAction<{modal: keyof InitialState["openModals"], value: boolean}>) => {
      state.openModals[action.payload.modal] = action.payload.value;
    },
    documentDetailListReceived: (
      state,
      action: PayloadAction<{leadId: number; documentDetailList: DocumentDetail[]}>,
    ) => {
      state.documentDetailList[action.payload.leadId] = action.payload.documentDetailList;
    },
    docRejectionDetailsReceived: (
      state,
      action: PayloadAction<{leadId: number; documentRejectionDetails: DocRejectionDetail[]}>,
    ) => {
      state.documentRejectionDetails[action.payload.leadId] =
        action.payload.documentRejectionDetails;
    },
    setReload: (state) => {
      state.reload = !state.reload;
    },
    setActionError,
    setActionLoading,
    setActionSuccess,
  },
});

export const selectLeadDocVerificationAction = (
  state: RootState,
  action: LeadDocVerificationActions,
) => state.leadDocVerification.actions[action];

const {
  selectAll: selectAllLeadDocVerification,
  selectById: selectLeadDocVerificationById,
} = leadDocVerificationJobAdaptor.getSelectors(
  (state: RootState) => state.leadDocVerification.leadDocVerificationList,
);

const selectLeadDocTimeline = (state: RootState, leadId: number) =>
  state.leadDocVerification.leadDocTimeline[leadId];
const selectOpenModals = (state: RootState) => state.leadDocVerification.openModals;
const selectDocumentDetailList = (state: RootState, leadId: number) =>
  state.leadDocVerification.documentDetailList[leadId];
const selectDocumentRejectionDetails = (state: RootState, leadId: number) =>
  state.leadDocVerification.documentRejectionDetails[leadId];
const selectLeadDocVerificationReload = (state: RootState) => state.leadDocVerification.reload;

export const {
  leadDocVerificationListReceived,
  leadDocVerificationTimelineReceived,
  setActionError: setLeadDocVerificationActionError,
  setActionLoading: setLeadDocVerificationActionLoading,
  setActionSuccess: setLeadDocVerificationActionSuccess,
  toggleModal: toggleLeadDocVerificationModal,
  documentDetailListReceived,
  docRejectionDetailsReceived,
  setReload: setLeadDocVerificationReload,
} = leadDocVerificationSlice.actions;
export {
  selectAllLeadDocVerification,
  selectLeadDocVerificationById,
  selectLeadDocTimeline,
  selectOpenModals,
  selectDocumentDetailList,
  selectDocumentRejectionDetails,
  selectLeadDocVerificationReload,
};
export default leadDocVerificationSlice.reducer;
