import React, {useEffect} from "react";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {IGDialog, IGLoading} from "app/components";
import {Box, Typography} from "@mui/material";
import IGTable, {IGTableColsInterface} from "app/components/IGTable";
import {FraudTransactionAnalysis} from "app/models/activeLoans/activeLoans.model";

interface FraudTransactionAnalysisModalProps {
  crId: number;
}
const FraudTransactionAnalysisModal = ({crId}: FraudTransactionAnalysisModalProps) => {
  const dispatch = useAppDispatch();
  const {fraudTransactionModal: isFraudTransactionAnalysisModalOpen} = useAppSelector(
    activeLoansSelectors.getOpenModals,
  );
  const {getFraudTransactionAnalysis: isFraudAnalysisLoading} = useAppSelector(
    activeLoansSelectors.getLoading,
  );
  const fraudTransactionAnalysis = useAppSelector(state =>
    activeLoansSelectors.getFraudTransactionAnalysisByCrId(state, crId),
  );

  const tableCols: IGTableColsInterface<FraudTransactionAnalysis> = [
    {
      field: "fraudType",
      headerName: "Fraud Type",
      headerClassName: "fraud-transaction-header",
      sortable: false,
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "fraudDescription",
      headerName: "Fraud Description",
      flex: 2,
      headerClassName: "fraud-transaction-header",
      sortable: false,
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "fraudCategory",
      headerName: "Fraud Category",
      headerClassName: "fraud-transaction-header",
      sortable: false,
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "isFraudDetected",
      headerName: "Fraud Detected",
      headerClassName: "fraud-transaction-header",
      sortable: false,
      renderCell: ({value}) => <Typography>{value ? "Yes" : "No"}</Typography>,
    },
  ];

  useEffect(() => {
    dispatch(
      activeLoansActions.getFraudTransactionAnalysis({
        crId: crId,
      }),
    );
  }, []);
  return (
    <>
      <IGDialog
        maxWidth="lg"
        hideFooter
        title={"Fraud Transaction Analysis".toLowerCase()}
        titleProps={{
          sx: {
            textTransform: "capitalize",
          },
        }}
        open={isFraudTransactionAnalysisModalOpen}
        onClose={() =>
          dispatch(
            toggleModals({
              type: "fraudTransactionModal",
              value: false,
            }),
          )
        }
      >
        {isFraudAnalysisLoading ? (
          <IGLoading height="10vh" />
        ) : (
          <Box>
            {fraudTransactionAnalysis?.length === 0 ? (
              <Typography>No Fraud Transaction Found!</Typography>
            ) : (
              <IGTable
                getRowId={row => row?.fraudCategory}
                tableCols={tableCols}
                tableData={fraudTransactionAnalysis}
                hideFooter
                hideFooterPagination
                sx={{
                  "& .fraud-transaction-header": {
                    fontWeight: "bold",
                    color: "#fff",
                    backgroundColor: "#000000",
                  },
                  "& .fraud-transaction-header .MuiDataGrid-columnSeparator": {
                    display: "none",
                  },
                  "& .fraud-transaction-header .MuiSvgIcon-root": {
                    fill: "white",
                  },
                }}
              />
            )}
          </Box>
        )}
      </IGDialog>
    </>
  );
};

export default FraudTransactionAnalysisModal;
