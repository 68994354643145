import {Stack, Typography} from "@mui/material";
import {IGDialog, IGSelectMultiple, IGTextField} from "app/components";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {leadDocVerificationActions} from "app/store/leadDocVerification";
import {selectDocumentRejectionDetails, selectLeadDocVerificationAction, toggleLeadDocVerificationModal} from "app/store/leadDocVerification/leadDocVerification.reducer";
import React from "react";

const RejectKYCModal = ({leadId}: { leadId: number }) => {
  const dispatch = useAppDispatch();
  const documentRejectionDetails = useAppSelector(state =>
    selectDocumentRejectionDetails(state, leadId),
  );
  const {
    loading: rejectKycLoading,
  } = useAppSelector(state => selectLeadDocVerificationAction(state, "rejectKyc"));
  const [selectedDocumentTypes, setSelectedDocumentTypes] = React.useState<any>([]);
  const [selectedRejectionReasons, setSelectedRejectionReasons] = React.useState<any>({});
  const [remarks, setRemarks] = React.useState("");

  const documentTypeList = documentRejectionDetails?.map(document => ({
    text: document.documentType.replaceAll("_", " "),
    value: document.documentType,
  }));

  const documentTypeRejectionOptions = selectedDocumentTypes.map((selectedDocumentType: string) => {
    const document = documentRejectionDetails.find(
      document => document.documentType === selectedDocumentType,
    );
    const rejectionOptions = document?.rejectionReasonList.map(reason => ({
      text: reason.description,
      value: reason.rejectionReasonMasterConfigId,
    }));

    return {
      documentType: selectedDocumentType,
      rejectionOptions,
    };
  });

  const isSubmitDisabled =
    selectedDocumentTypes.length === 0 ||
    !remarks ||
    documentTypeRejectionOptions.some(
      ({documentType}: {
        documentType: string;
        rejectionOptions: Array<{ text: string; value: number }>;
      }) =>
        !selectedRejectionReasons[documentType]?.length,
    );

  const onClose = () => {
    dispatch(toggleLeadDocVerificationModal({modal: "rejectKyc", value: false}));
  };

  const handleSubmit = () => {
    const fwcLeadDocRejectRequestDtoList = selectedDocumentTypes.map(
      (documentType: string) => ({
        documentType,
        fwcParentLeadMasterDocumentConfigMappingId: documentRejectionDetails.find(
          document => document.documentType === documentType,
        )?.parentLeadMasterDocumentConfigMappingId,
        rejectionReasonMasterConfigIdList: selectedRejectionReasons[documentType],
      }),
    );
    dispatch(
      leadDocVerificationActions.rejectKyc({
        leadId,
        remark: remarks,
        fwcLeadDocRejectRequestDtoList,
      }),
    );
  };

  return (
    <IGDialog
      open
      title="Reject KYC"
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleSubmit}
      confirmProps={{
        disabled: isSubmitDisabled,
        loading: rejectKycLoading,
      }}
      cancelProps={{
        disabled: rejectKycLoading,
      }}
      maxWidth="md"
    >
      <Stack gap={2}>
        <Stack gap={2}>
          <Typography fontWeight="bold" color="text.secondary">
            Rejection Category
          </Typography>
          <IGSelectMultiple
            label="Select Rejection Category"
            name="documentType"
            value={selectedDocumentTypes}
            options={documentTypeList}
            onChange={selected => {
              setSelectedDocumentTypes(selected.target.value);
            }}
          />
        </Stack>
        {documentTypeRejectionOptions.map(
          ({
            documentType,
            rejectionOptions,
          }: {
            documentType: string;
            rejectionOptions: Array<{ text: string; value: number }>;
          }) => (
            <Stack gap={2}>
              <Typography fontWeight="bold" color="text.secondary">
                Rejection reason for {documentType.replaceAll("_", " ")}
              </Typography>
              <IGSelectMultiple
                key={documentType}
                label={"Select Rejection Reason"}
                name={documentType}
                value={selectedRejectionReasons[documentType] || []}
                options={rejectionOptions}
                chipOptions={{
                  variant: "outlined",
                }}
                onChange={event => {
                  setSelectedRejectionReasons({
                    ...selectedRejectionReasons,
                    [documentType]: event.target.value,
                  });
                }}
              />
            </Stack>
          ),
        )}

        <Stack gap={2}>
          <Typography fontWeight="bold" color="text.secondary">
            Remarks
          </Typography>
          <IGTextField
            multiline
            rows={4}
            placeholder="Enter remarks"
            name="remarks"
            value={remarks}
            onChange={event => setRemarks(event.target.value)}
          />
        </Stack>
      </Stack>
    </IGDialog>
  );
};

export default RejectKYCModal;
