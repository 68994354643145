import React, {useState} from "react";
import styled from "@emotion/styled";
import {Box, Typography, Stack} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import {activeLoansSelectors} from "app/store/activeLoans";
import TransactionAnalysisModal from "./Dialogs/TransactionAnalysisModal";
import FraudTransactionAnalysisModal from "./Dialogs/FraudTransactionAnalysisModal";

const SpendingStyledBox = styled(Box)`
  border-radius: 4px;
  height: 40px;
  background-color: #e6f3fa;
  padding: 8px;
  margin: 6px;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #d4ebf7;
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const spendingAnalysisTypes = ["DEBIT_PROFILE", "CREDIT_PROFILE", "FRAUDS"];

const SpendingAnalysis = ({crId}: { crId: number }) => {
  const dispatch = useAppDispatch();
  const {
    transactionAnalysisModal: isTransactionModalOpen,
    fraudTransactionModal: isFraudTransactionModalOpen,
  } = useAppSelector(activeLoansSelectors.getOpenModals);
  const [selectedTransactionType, setSelectedTransactionType] = useState("");

  const onClickSpendingAnalysisType = (type: string) => {
    setSelectedTransactionType(type);
    if (type === "DEBIT_PROFILE" || type === "CREDIT_PROFILE") {
      dispatch(
        toggleModals({
          type: "transactionAnalysisModal",
          value: true,
        }),
      );
    } else if (type === "FRAUDS") {
      dispatch(
        toggleModals({
          type: "fraudTransactionModal",
          value: true,
        }),
      );
    }
  };
  return (
    <Box my={2} pl={1}>
      <Typography fontSize="14px" fontFamily="roboto" fontWeight={600} lineHeight="48px">
        Spending Analysis
      </Typography>
      <Box display="flex" gap={1}>
        {spendingAnalysisTypes.map(i => (
          <SpendingStyledBox onClick={() => onClickSpendingAnalysisType(i)}>
            <Stack
              width="fit-content"
              display="flex"
              justifyContent="space-between"
              direction="row"
              gap={1}
            >
              <Typography variant="body1" fontWeight={600}>
                {i?.replaceAll("_", " ")}
              </Typography>
              <KeyboardArrowRightIcon />
            </Stack>
          </SpendingStyledBox>
        ))}
      </Box>
      {isTransactionModalOpen && (
        <TransactionAnalysisModal crId={crId} transactionType={selectedTransactionType} />
      )}
      {isFraudTransactionModalOpen && <FraudTransactionAnalysisModal crId={crId} />}
    </Box>
  );
};

export default SpendingAnalysis;
