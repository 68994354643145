import {ExpandMoreRounded} from "@mui/icons-material";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import {
  IGButton,
  IGDialog,
  IGDocumentViewer,
  IGDrawer,
  IGLoading,
  IGTextField,
} from "app/components";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {leadDocVerificationActions} from "app/store/leadDocVerification";
import {
  selectDocumentDetailList,
  selectLeadDocVerificationAction,
  selectOpenModals,
  toggleLeadDocVerificationModal,
} from "app/store/leadDocVerification/leadDocVerification.reducer";
import React, {useEffect} from "react";
import RejectKYCModal from "./RejectKYCModal";
import styled from "@emotion/styled";
import LeadCreditHistory from "../../Lead/CRMLead/LeadCreditHistory";

const StyledAccordion = styled(Accordion)`
  border: solid 1px #f3e5b6;
  border-radius: 4px;

  &:before: {
    display: "none"; // This removes the top border
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  height: 2.5rem;
  width: 100%;
  background-color: #fbf8ee;
  border-bottom: solid 1px #f3e5b6;
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DocumentVerificationDrawer = ({
  leadId,
  onClose,
}: {
  leadId: number;
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const leadDocumentList = useAppSelector(state => selectDocumentDetailList(state, leadId));
  const {loading: leadDocDetailsLoading} = useAppSelector(state =>
    selectLeadDocVerificationAction(state, "getDocumentDetailList"),
  );
  const {
    approveKyc: openApproveModal,
    rejectKyc: openRejectModal,
  } = useAppSelector(selectOpenModals);
  const [checked, setChecked] = React.useState<Record<string, boolean>>({});
  const [remarks, setRemarks] = React.useState("");

  const isApproveDisabled = Boolean(
    !Object.values(checked).length || Object.values(checked).some(value => !value),
  );

  useEffect(() => {
    dispatch(leadDocVerificationActions.getDocumentDetailList({leadId}));
  }, [leadId, dispatch]);

  useEffect(() => {
    const initialChecked: Record<string, boolean> = {};
    leadDocumentList?.forEach(document => {
      initialChecked[document.documentType] = false;
    });
    setChecked(initialChecked);
  }, [leadDocumentList]);

  return (
    <>
      <IGDrawer
        open
        onClose={onClose}
        title="Provisional KYC Verification (Before LM Visit)"
        PaperProps={{sx: {width: "70vw"}}}
      >
        <Box display="flex" justifyContent="flex-end">
          <IGButton
            variant="outlined"
            onClick={() => {
              dispatch(leadDocVerificationActions.getLeadDocTimeline({leadId}));
              dispatch(toggleLeadDocVerificationModal({modal: "actionHistory", value: true}));
            }}
            sx={{margin: 2, marginBottom: 0}}
          >
            Action History
          </IGButton>
        </Box>
        {leadDocDetailsLoading && <IGLoading />}
        <Box p={2}>
          <StyledAccordion
            key="creditHistory"
            elevation={0}
          >
            <StyledAccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography fontWeight={600}>Credit Report Detail</Typography>
            </StyledAccordionSummary>
            <AccordionDetails>
              <LeadCreditHistory leadId={leadId} isLsqFlow={false} />
            </AccordionDetails>
          </StyledAccordion>
        </Box>
        {leadDocumentList?.length ? (
          <Stack pb={4} px={2}>
            {leadDocumentList?.map(document => (
              <StyledAccordion
                key={document.documentType}
                elevation={0}
                TransitionProps={{unmountOnExit: true}}
              >
                <StyledAccordionSummary expandIcon={<ExpandMoreRounded />}>
                  <Typography fontWeight={600}>{document.documentTypeDisplayValue}</Typography>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <Box sx={{display: "flex", gap: 2, flexWrap: "wrap"}}>
                    {document.fileDetailList.map((file, fileIndex) => (
                      <IGDocumentViewer
                        key={fileIndex}
                        document={{
                          fileUrl: file.s3FileUrl,
                          documentType: document.documentTypeDisplayValue,
                        }}
                      />
                    ))}
                  </Box>
                </AccordionDetails>
                <AccordionActions
                  sx={{
                    justifyContent: "flex-start",
                    borderTop: "solid 1px #f3e5b6",
                  }}
                >
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked[document.documentType]}
                          onChange={() =>
                            setChecked({
                              ...checked,
                              [document.documentType]: !checked[document.documentType],
                            })
                          }
                        />
                      }
                      label=" I agree I have checked all the details and are correct"
                    />
                  </FormControl>
                </AccordionActions>
              </StyledAccordion>
            ))}
            <Stack flexDirection="row" gap={2} mt={2}>
              <IGButton
                color="golden"
                disabled={isApproveDisabled}
                onClick={() => {
                  dispatch(toggleLeadDocVerificationModal({modal: "approveKyc", value: true}));
                }}
              >
                Approve KYC
              </IGButton>
              <IGButton
                variant="outlined"
                color="error"
                onClick={() => {
                  dispatch(leadDocVerificationActions.getDocumentRejectionDetails({leadId}));
                  dispatch(toggleLeadDocVerificationModal({modal: "rejectKyc", value: true}));
                }}
              >
                Reject KYC
              </IGButton>
            </Stack>
          </Stack>
        ) : (
          <Box p={2}>
            <Typography>No documents found</Typography>
          </Box>
        )}
      </IGDrawer>
      <IGDialog
        open={openApproveModal}
        title="Approve KYC"
        onClose={() => toggleLeadDocVerificationModal({modal: "approveKyc", value: false})}
        onCancel={() => toggleLeadDocVerificationModal({modal: "approveKyc", value: false})}
        onConfirm={() => {
          dispatch(
            leadDocVerificationActions.approveKyc({
              leadId,
              remark: remarks,
            }),
          );
          onClose();
        }}
      >
        <Stack>
          <Typography>Please provide more details if necessary</Typography>
          <IGTextField
            multiline
            rows={4}
            placeholder="Enter remarks"
            name="remarks"
            value={remarks}
            onChange={event => setRemarks(event.target.value)}
          />
        </Stack>
      </IGDialog>
      {openRejectModal && (
        <RejectKYCModal leadId={leadId} />
      )}
    </>
  );
};

export default DocumentVerificationDrawer;
