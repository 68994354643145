export const RolesEnum = {
  ADMIN: "ADMIN",
  CST: "CST",
  A_HIMANSHU: "A_HIMANSHU",
  AUGMONT: "AUGMONT",
  ACCOUNT_TEAM: "ACCOUNT_TEAM",
  CREDIT_MANAGER: "CREDIT_MANAGER",
  FINANCE_TEAM: "FINANCE_TEAM",
  SCHEDULER: "SCHEDULER",
  SCHEDULER_TEAM_LEAD: "SCHEDULER_TEAM_LEAD",
  BUSINESS: "BUSINESS",
  FOUNDER: "FOUNDER",
  CUSTOMER_SUPPORT: "CUSTOMER_SUPPORT",
  SENIOR_OPERATIONS: "SENIOR_OPERATIONS",
  CUSTOMER_SUPPORT_TEAM_LEAD: "CUSTOMER_SUPPORT_TEAM_LEAD",
  INSIDE_SALES_LOAN: "INSIDE_SALES_LOAN",
  INSIDE_SALES_LOCKER: "INSIDE_SALES_LOCKER",
  INSIDE_SALES_TEAM_LEAD: "INSIDE_SALES_TEAM_LEAD",
  CITY_HEAD: "CITY_HEAD",
  INSIDE_SALES_AFFILIATE: "INSIDE_SALES_AFFILIATE",
  LENDER: "LENDER",
  LOAN_MANAGER: "LOAN_MANAGER",
  LOAN_MANAGER_LEAD: "LOAN_MANAGER_LEAD",
  AUDIT_MANAGER_HEAD: "AUDIT_MANAGER_HEAD",
  CLOSURE_RETENTION_TEAM: "CLOSURE_RETENTION_TEAM",
  ISR_RETENTION_TEAM_LEAD: "ISR_RETENTION_TEAM_LEAD",
  TRAINING_MANAGER: "TRAINING_MANAGER",
  LEAD_AFFILIATES: "LEAD_AFFILIATES",
  SUPPORT_AND_RETENTION: "SUPPORT_AND_RETENTION",
  USER_ADMIN: "USER_ADMIN",
  LENDER_PIRAMAL_ADMIN: "LENDER_PIRAMAL_ADMIN",
  LENDER_SHIVALIK_ADMIN: "LENDER_SHIVALIK_ADMIN",
  LENDER_FINCARE_ADMIN: "LENDER_FINCARE_ADMIN",
  LENDER_SHIVALIK_READ_ONLY: "LENDER_SHIVALIK_READ_ONLY",
  LENDER_PIRAMAL_READ_ONLY: "LENDER_PIRAMAL_READ_ONLY",
  COLLECTION_MANAGER:"COLLECTION_MANAGER",
  LOAN_OPS_ADMIN:"LOAN_OPS_ADMIN",
  AMEYO_USER: "AMEYO_USER",
  LEAD_OPS:"LEAD_OPS",
  SCHEDULING_ADMIN:"SCHEDULING_ADMIN",
  LEAD_REATTRIBUTOR: "LEAD_REATTRIBUTOR",
  ENGINEERING:"ENGINEERING",
};
