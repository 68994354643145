import React, {useState, useEffect, ChangeEvent} from "react";
import {Box, Grid} from "@mui/material";
import Filter from "./Filter";
import {useAppSelector, useAppDispatch} from "app/store/hooks";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import ActiveLoanListing from "./ActiveLoanListing";
import {resetFiltersState, setActiveTab} from "app/store/activeLoans/activeLoans.reducer";
import {IGSection, IGSectionContent, IGSectionHeader} from "app/components";
import {StyledTabsContainer, StyledTab} from "app/components/Tabs/styles";
import {ActiveLoansType} from "app/models/activeLoans/activeLoans.model";
import DocVerificationQueue from "./DocVerificationQueue";
import RenewRebookListing from "./RenewRebookListing";

const ActiveLoan = () => {
  const [drawer, setDrawer] = useState(false);
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector(activeLoansSelectors.getActiveTab);
  const activeLoansTabsList = useAppSelector(activeLoansSelectors.getActiveLoansTabsList);
  const filtersData = useAppSelector(activeLoansSelectors.getFilters);

  const onChangeTab = (event: ChangeEvent<{}>, newValue: ActiveLoansType) => {
    dispatch(setActiveTab(newValue));
  };

  useEffect(() => {
    dispatch(activeLoansActions.getCitiesList());
    dispatch(activeLoansActions.getActiveLoansTabsList());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetFiltersState());
    };
  }, []);

  return (
    <IGSection>
      <IGSectionHeader title="CURRENT ACTIVE JOBS">
        <Box mt={2} mb={-3}>
          <StyledTabsContainer value={activeTab} onChange={onChangeTab}>
            {activeLoansTabsList?.map((i: any, index: number) => (
              <StyledTab
                iconPosition="start"
                key={i.tabName + index}
                label={i.tabName}
                value={i.tabEnum}
                className={activeTab === i.tabEnum ? "selected" : ""}
              />
            ))}
          </StyledTabsContainer>
        </Box>
      </IGSectionHeader>

      <IGSectionContent>
        <Grid container bgcolor="#f5f5f5" spacing={2} px={2}>
          <Grid item xs={12}>
            <Filter />
          </Grid>

          <Grid item xs={12}>
            {activeTab === "DOC_VERIFICATION" && <DocVerificationQueue />}
            {activeTab === "CREDIT_QUEUE" && (
              <ActiveLoanListing drawer={drawer} setDrawer={setDrawer} filtersData={filtersData} />
            )}
            {activeTab === "ALL_LOANS" && (
              <ActiveLoanListing drawer={drawer} setDrawer={setDrawer} filtersData={filtersData} />
            )}
            {activeTab === "RENEW_REBOOK" && <RenewRebookListing filtersData={filtersData} />}
          </Grid>
        </Grid>
      </IGSectionContent>
    </IGSection>
  );
};

export default ActiveLoan;
