import React, {useEffect} from "react";
import {Box, Typography, Chip, Stack} from "@mui/material";
import BusinessCreditScore from "./businessCreditScore.png";
import {IGButton, IGLoading} from "app/components";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {
  resetBtScoreCardDisclaimer,
  toggleModals,
} from "app/store/activeLoans/activeLoans.reducer";
import {activeLoansSelectors} from "app/store/activeLoans";
import BTScoreCard from "app/pages/home/GoldLoan/ActiveLoan/BTScoreCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import styled from "@emotion/styled";

const JourneyIntroText = styled(Typography)`
  font-weight: 600;
  line-height: 22px;
  font-size: 14px;
`;
const BTScoreIntroductoryCard = ({crId}: { crId: number }) => {
  const dispatch = useAppDispatch();

  const {getBtScoreCardConfig: isBtScoreCardConfigLoading} = useAppSelector(
    activeLoansSelectors.getLoading,
  );
  const {configRuleDtoList, isScoreCardAvailable} =
    useAppSelector(state => activeLoansSelectors.getBtScoreCardConfig(state, crId)) ?? {};

  const {btScoreCard: isBtScoreCardModalOpen} = useAppSelector(
    activeLoansSelectors.getOpenModals,
  );
  const isBtScoreCardCheckedByCm = useAppSelector(
    activeLoansSelectors.getBtScoreCardMilestoneCheckedByCm,
  );

  useEffect(() => {
    return () => {
      dispatch(resetBtScoreCardDisclaimer());
    };
  }, []);

  return (
    <>
      {isBtScoreCardConfigLoading ? (
        <IGLoading height="5vh" />
      ) : (
        <Box
          height="250px"
          width="755px"
          bgcolor="#FAFAFA"
          border="1px solid #0000001F"
          p={1}
          my={2}
        >
          <Box display="flex" gap={1}>
            <Box
              width="72px"
              height="72px"
              component="img"
              src={BusinessCreditScore}
              alt="Descriptive Alt Text"
            />
            <Box>
              <JourneyIntroText variant="subtitle2">
                <Chip label="New" variant="outlined" color="primary" /> Introducing BT - ScoreCard
              </JourneyIntroText>
              <Typography variant="subtitle2" fontSize="14px" fontWeight={400} lineHeight="22px">
                Now you can manage balance transfer journeys all under the same roof.
              </Typography>
              <JourneyIntroText mt={2} variant="subtitle2">
                Journeys released:
              </JourneyIntroText>
              <Stack direction="column" gap={1}>
                {configRuleDtoList?.map(config => (
                  <Stack
                    direction="row"
                    gap={1}
                    key={config?.ruleName}
                    justifyContent="space-between"
                    width="80%"
                  >
                    <Stack width="50%" flexDirection="row" justifyContent="space-between">
                      <Typography
                        variant="subtitle2"
                        fontSize="14px"
                        fontWeight={400}
                        lineHeight="22px"
                      >
                        {config?.ruleMetaDataJson?.headerDisplayName}
                      </Typography>
                      <Typography>-</Typography>
                    </Stack>
                    <Stack flexDirection="row" gap={1}>
                      {isBtScoreCardCheckedByCm[config?.ruleName]?.isDisclaimerChecked ? (
                        <CheckCircleIcon
                          sx={{
                            color: "#2E7D32",
                          }}
                        />
                      ) : (
                        <PendingActionsIcon
                          sx={{
                            color: "#ED6C02",
                          }}
                        />
                      )}
                      <Typography color="gray">
                        {isBtScoreCardCheckedByCm[config?.ruleName]?.isDisclaimerChecked
                          ? "Verified"
                          : "Verification Pending"}{" "}
                      </Typography>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Box>
          {isScoreCardAvailable && (
            <Box mt={2} display="flex" justifyContent="flex-end">
              <IGButton
                onClick={() =>
                  dispatch(
                    toggleModals({
                      type: "btScoreCard",
                      value: true,
                    }),
                  )
                }
              >
                GO TO BT SCORECARD
              </IGButton>
            </Box>
          )}
        </Box>
      )}

      {isBtScoreCardModalOpen && <BTScoreCard />}
    </>
  );
};

export default BTScoreIntroductoryCard;
