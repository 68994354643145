export const cmStatusEnum = {
  ASSIGNED_TO_ME: "ASSIGNED_TO_ME",
  // PENDING: "PENDING",
  MY_PENDING_TASK:"MY_PENDING_TASK",
  PENDING_FOR_ACTION: "ACTION_PENDING",
  ALL_CM_STATUS: "All",
};

export const sourceEnum = {
  CONSUMER_APP: "CONSUMER_APP",
};