import {LeadApiInterface, LeadApiResources, PostLeadReAttributePayload} from "./lead.types";
import {goldApiService} from "app/infra/plugins/axios";

const LEAD_ENDPOINTS: LeadApiResources = {
  GET_PARTNER_LEAD_LIST: {
    URL: "/lead/partner/partner-lead-list",
    METHOD: "GET",
  },
  DOWNLOAD_CSV: {
    URL: "/lead/partner/partner-lead-bulk-upload-template",
    METHOD: "GET",
  },
  ADD_NEW_LEAD: {
    URL: "/lead/partner/partner-lead",
    METHOD: "POST",
  },
  BULK_LEAD_UPLOAD: {
    URL: "/lead/partner/partner-lead-bulk-upload-request",
    METHOD: "POST",
  },
  GET_ANALYTICS: {
    URL: "/lead/partner/partner-lead-analytics",
    METHOD: "GET",
  },
  GET_LEAD_UNQUALIFIED_REASONS: {
    URL: "/lead/unqualified-reason-list",
    METHOD: "GET",
  },
  GET_PARENT_LEAD_ID: {
    URL: "/dsa-lead/:crmLeadId/lead-detail",
    METHOD: "GET",
  },
  GET_PARENT_LEAD_ID_CREDIT_DETAIL: {
    URL: "/parent-lead/credit-report/:parentLeadId/credit-detail",
    METHOD: "GET",
  },
  GET_PARENT_LEAD_DOCUMENT_CONFIG: {
    URL: "/parent-lead/v1/document/:parentLeadId/config",
    METHOD: "GET",
  },
  GET_PARENT_LEAD_DOCUMENT: {
    URL:
      "/parent-lead/v1/document/:parentLeadId/lead-master-document",
    METHOD: "GET",
  },
  DELETE_PARENT_LEAD_DOCUMENT: {
    URL:
      "/parent-lead/v1/document/:parentLeadId/lead-master-document",
    METHOD: "PUT",
  },
  UPDATE_PARENT_LEAD_DOCUMENT: {
    URL: "/parent-lead/v1/document/:parentLeadId/lead-master-document",
    METHOD: "POST",
  },
  UPDATE_LEAD_ON_LSQ: {
    URL: "/parent-lead/v1/document/:parentLeadId/update-on-lsq",
    METHOD: "PUT",
  },
  POST_LEAD_REATTRIBUTE: {
    URL: "/lead/admin/reattribute-lead",
    METHOD: "POST",
  },
};

const leadApi: LeadApiInterface = {
  getPartnerLeadList: async payload => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.GET_PARTNER_LEAD_LIST,
      options: {
        queryParams: {...payload},
      },
    });
  },
  downloadCSV: async () => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.DOWNLOAD_CSV,
    });
  },
  addNewLead: async payload => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.ADD_NEW_LEAD,
      options: {
        data: {...payload},
      },
    });
  },
  bulkLeadUpload: async payload => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.BULK_LEAD_UPLOAD,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      options: {
        data: payload.formData,
      },
    });
  },
  getAnalytics: async ({startTime, endTime}) => {
    const payload: Record<string, any> = {};
    if (startTime && endTime) {
      payload.startTime = startTime;
      payload.endTime = endTime;
    }
    return await goldApiService({
      resource: LEAD_ENDPOINTS.GET_ANALYTICS,
      options: {
        queryParams: {...payload},
      },
    });
  },
  getLeadUnQualifiedReasons: async () => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.GET_LEAD_UNQUALIFIED_REASONS,
    });
  },
  getParentLeadId: async ({crmLeadId}) => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.GET_PARENT_LEAD_ID,
      options: {
        pathVars: {
          crmLeadId,
        },
      },
    });
  },
  getParentLeadIdCreditDetail: async ({parentLeadId}) => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.GET_PARENT_LEAD_ID_CREDIT_DETAIL,
      options: {
        pathVars: {
          parentLeadId,
        },
      },
    });
  },
  getParentLeadDocumentConfig: async ({parentLeadId}) => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.GET_PARENT_LEAD_DOCUMENT_CONFIG,
      options: {
        pathVars: {
          parentLeadId,
        },
      },
    });
  },
  getParentLeadDocument: async ({parentLeadId, parentLeadMasterDocumentConfigId}) => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.GET_PARENT_LEAD_DOCUMENT,
      options: {
        queryParams: {
          parentLeadMasterDocumentConfigId,
        },
        pathVars: {
          parentLeadId,
        },
      },
    });
  },
  deleteParentLeadDocument: async ({parentLeadId, parentLeadDocumentFileDetailId}) => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.DELETE_PARENT_LEAD_DOCUMENT,
      options: {
        queryParams: {
          parentLeadDocumentFileDetailId,
        },
        pathVars: {
          parentLeadId,
        },
      },
    });
  },
  updateParentLeadDocument: async ({parentLeadId, formData}) => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.UPDATE_PARENT_LEAD_DOCUMENT,
      options: {
        pathVars: {
          parentLeadId,
        },
        data: formData,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateLeadOnLSQ: async ({parentLeadId}) => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.UPDATE_LEAD_ON_LSQ,
      options: {
        pathVars: {
          parentLeadId,
        },
      },
    });
  },
  postLeadReAttribute: async ({
    fwcLeadId,
    utmSource,
    utmTerm,
    utmDetail,
    remark,
  }: PostLeadReAttributePayload) => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.POST_LEAD_REATTRIBUTE,
      options: {
        data: {
          fwcLeadId,
          utmSource,
          utmTerm,
          utmDetail,
          remark,
        },
      },
    });
  },
};

export {LEAD_ENDPOINTS};
export default leadApi;
