import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "app/infra/services/api";
import {
  GetDocVerificationListPayload,
  LeadIdPayload,
  RejectKYCPayload,
} from "app/infra/services/api/leadDoc/leadDoc.api";
import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {
  docRejectionDetailsReceived,
  documentDetailListReceived,
  leadDocVerificationListReceived,
  leadDocVerificationTimelineReceived,
  setLeadDocVerificationActionError,
  setLeadDocVerificationActionLoading,
  setLeadDocVerificationActionSuccess,
  setLeadDocVerificationReload,
  toggleLeadDocVerificationModal,
} from "./leadDocVerification.reducer";
import leadDocVerificationActions from "./leadDocVerification.actions";
import {snackBarActions} from "../snackbar";

function* getLeadDocVerificationListFlow(action: PayloadAction<GetDocVerificationListPayload>) {
  yield put(setLeadDocVerificationActionLoading({action: "getLeadDocVerificationList"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.leadDocApi.getDocVerificationList, action.payload);
  if (response) {
    yield put(leadDocVerificationListReceived(response.result));
    yield put(setLeadDocVerificationActionSuccess({action: "getLeadDocVerificationList"}));
  } else {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong",
        variant: "error",
      }),
    );
    yield put(
      setLeadDocVerificationActionError({
        action: "getLeadDocVerificationList",
        error: error.message || "Something went wrong",
      }),
    );
  }
}

function* getLeadDocTimelineFlow(action: PayloadAction<LeadIdPayload>) {
  yield put(setLeadDocVerificationActionLoading({action: "getLeadDocTimeline"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.leadDocApi.getDocTimeline, action.payload);
  if (response) {
    yield put(
      leadDocVerificationTimelineReceived({
        leadId: action.payload.leadId,
        timeline: response,
      }),
    );
    yield put(setLeadDocVerificationActionSuccess({action: "getLeadDocTimeline"}));
  } else {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong",
        variant: "error",
      }),
    );
    yield put(
      setLeadDocVerificationActionError({
        action: "getLeadDocTimeline",
        error: error.message || "Something went wrong",
      }),
    );
  }
}

function* approveKycFlow(action: PayloadAction<LeadIdPayload>) {
  yield put(setLeadDocVerificationActionLoading({action: "approveKyc"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.leadDocApi.approveKYC, action.payload);
  if (response) {
    yield put(setLeadDocVerificationReload());
    yield put(setLeadDocVerificationActionSuccess({action: "approveKyc"}));
    yield put(toggleLeadDocVerificationModal({modal: "approveKyc", value: false}));
    yield put(toggleLeadDocVerificationModal({modal: "docVerificationDrawer", value: false}));
    yield put(
      snackBarActions.open({
        message: "Pre-LM Visit KYC Approved",
        variant: "success",
      }),
    );
  } else {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong",
        variant: "error",
      }),
    );
    yield put(
      setLeadDocVerificationActionError({
        action: "approveKyc",
        error: error.message || "Something went wrong",
      }),
    );
  }
}

function* rejectKycFlow(action: PayloadAction<RejectKYCPayload>) {
  yield put(setLeadDocVerificationActionLoading({action: "rejectKyc"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.leadDocApi.rejectKYC, action.payload);
  if (response) {
    yield put(setLeadDocVerificationReload());
    yield put(setLeadDocVerificationActionSuccess({action: "rejectKyc"}));
    yield put(toggleLeadDocVerificationModal({modal: "rejectKyc", value: false}));
    yield put(toggleLeadDocVerificationModal({modal: "docVerificationDrawer", value: false}));
    yield put(
      snackBarActions.open({
        message: "Pre-LM Visit KYC Rejected",
        variant: "success",
      }),
    );
  } else {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong",
        variant: "error",
      }),
    );
    yield put(
      setLeadDocVerificationActionError({
        action: "rejectKyc",
        error: error.message || "Something went wrong",
      }),
    );
  }
}

function* skipVerificationFlow(action: PayloadAction<LeadIdPayload>) {
  yield put(setLeadDocVerificationActionLoading({action: "skipVerification"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.leadDocApi.skipVerification, action.payload);
  if (!error) {
    yield put(setLeadDocVerificationReload());
    yield put(setLeadDocVerificationActionSuccess({action: "skipVerification"}));
    yield put(toggleLeadDocVerificationModal({modal: "skipVerification", value: false}));
  } else {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong",
        variant: "error",
      }),
    );
    yield put(
      setLeadDocVerificationActionError({
        action: "skipVerification",
        error: error.message || "Something went wrong",
      }),
    );
  }
}

function* assignJobToUserFlow(action: PayloadAction<LeadIdPayload>) {
  yield put(setLeadDocVerificationActionLoading({action: "assignJobToUser"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.leadDocApi.assignJobToUser, action.payload);
  if (response) {
    yield put(setLeadDocVerificationReload());
    yield put(setLeadDocVerificationActionSuccess({action: "assignJobToUser"}));
  } else {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong",
        variant: "error",
      }),
    );
    yield put(
      setLeadDocVerificationActionError({
        action: "assignJobToUser",
        error: error.message || "Something went wrong",
      }),
    );
  }
}

function* getDocumentDetailListFlow(action: PayloadAction<LeadIdPayload>) {
  yield put(setLeadDocVerificationActionLoading({action: "getDocumentDetailList"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.leadDocApi.getLeadDocumentList, action.payload);
  if (response) {
    yield put(
      documentDetailListReceived({
        leadId: action.payload.leadId,
        documentDetailList: response.fwcParentLeadDocumentFileResponseDtoList,
      }),
    );
    yield put(setLeadDocVerificationActionSuccess({action: "getDocumentDetailList"}));
  } else {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong",
        variant: "error",
      }),
    );
    yield put(
      setLeadDocVerificationActionError({
        action: "getDocumentDetailList",
        error: error.message || "Something went wrong",
      }),
    );
  }
}

function* getDocumentRejectionDetailsFlow(action: PayloadAction<LeadIdPayload>) {
  yield put(setLeadDocVerificationActionLoading({action: "getDocumentRejectionDetails"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.leadDocApi.getDocumentRejectionDetails, action.payload);
  if (response) {
    yield put(
      docRejectionDetailsReceived({
        leadId: action.payload.leadId,
        documentRejectionDetails: response,
      }),
    );
    yield put(setLeadDocVerificationActionSuccess({action: "getDocumentRejectionDetails"}));
  } else {
    yield put(
      setLeadDocVerificationActionError({
        action: "getDocumentRejectionDetails",
        error: error.message || "Something went wrong",
      }),
    );
  }
}

function* unassignJobFlow(action: PayloadAction<LeadIdPayload>) {
  yield put(setLeadDocVerificationActionLoading({action: "unassignJob"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.leadDocApi.unassignJob, action.payload);
  if (response) {
    yield put(setLeadDocVerificationReload());
    yield put(setLeadDocVerificationActionSuccess({action: "unassignJob"}));
  } else {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong",
        variant: "error",
      }),
    );
    yield put(
      setLeadDocVerificationActionError({
        action: "unassignJob",
        error: error.message || "Something went wrong",
      }),
    );
  }
}

export default function* leadDocVerificationSaga() {
  yield takeLatest(
    leadDocVerificationActions.getLeadDocVerificationList,
    getLeadDocVerificationListFlow,
  );
  yield takeLatest(leadDocVerificationActions.getLeadDocTimeline, getLeadDocTimelineFlow);
  yield takeLatest(leadDocVerificationActions.approveKyc, approveKycFlow);
  yield takeLatest(leadDocVerificationActions.rejectKyc, rejectKycFlow);
  yield takeLatest(leadDocVerificationActions.skipVerification, skipVerificationFlow);
  yield takeLatest(leadDocVerificationActions.assignJobToUser, assignJobToUserFlow);
  yield takeLatest(leadDocVerificationActions.getDocumentDetailList, getDocumentDetailListFlow);
  yield takeLatest(
    leadDocVerificationActions.getDocumentRejectionDetails,
    getDocumentRejectionDetailsFlow,
  );
  yield takeLatest(leadDocVerificationActions.unassignJob, unassignJobFlow);
}
