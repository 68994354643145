import * as React from "react";
import {Backdrop, Modal, Fade} from "@mui/material";
import {Close} from "@mui/icons-material";
import {Box, BoxProps, Divider, IconButton, Typography} from "@mui/material";

interface IGModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  subtitle?: string;
  divider?: boolean
  children: React.ReactElement;
  width?: BoxProps["width"]
  showCloseButton?: boolean
  closeOnOutsideClick?: boolean
}

const modalContainerStyle = {
  position: "absolute" as "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "30%",
  maxHeight: "95vh",
  bgcolor: "background.paper",
  borderRadius: "11px",
  boxShadow: 24,
  p: 2,
  maxWidth: "90%",
};

const modalHeaderStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  mb: 1,
};

const modalTitleStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginBottom: 1,
  color: "text.primary",
};

const modalSubtitleStyle = {
  fontSize: "1rem",
  color: "text.secondary",
};

const modalContentStyle = {
  display: "flex",
  overflowY: "hidden",
  overflowX: "hidden",
  width: "100%",
};

const IGModal: React.FC<IGModalProps> = ({
  open,
  onClose,
  title="",
  subtitle="",
  divider = false,
  width= "auto",
  showCloseButton = true,
  closeOnOutsideClick = true,
  children=(<></>),
}) => {
  const handleClose = (event: any, reason: string = "") => {
    if (closeOnOutsideClick || reason !== "backdropClick") {
      onClose();
    }
  };

  return (
    <div>
      <Modal
        disableEnforceFocus
        aria-labelledby="ig-modal-title"
        aria-describedby="ig-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={modalContainerStyle} width={width}>
            <Box id="ig-modal-title" sx={modalHeaderStyle}>
              <Box>
                <Typography sx={modalTitleStyle} variant="h3">
                  {title}
                </Typography>
                <Typography sx={modalSubtitleStyle} variant="subtitle1">
                  {subtitle}
                </Typography>
              </Box>
              {showCloseButton && <IconButton onClick={handleClose}>
                <Close />
              </IconButton>}
            </Box>
            {divider && <Divider sx={{mt: 1, mb: 2}}/>}
            <Box id="ig-modal-description" sx={modalContentStyle}>{children}</Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default IGModal;
